import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface LoadingAPIState {
    loading: boolean,
}

const initialState: LoadingAPIState = {
    loading: false,
}

export const loadingAPISlice = createSlice({
    name: "loadingAPI",
    initialState,
    reducers: {
        showLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { showLoading } = loadingAPISlice.actions

export default loadingAPISlice.reducer