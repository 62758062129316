import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Screens from "constants/screens";
import DashboardLayout from "../../layout/dashboardLayout";

import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import { showLoading } from "store/slice/loadingAPI.slice";

const NotFoundScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);

    useEffect(() => {
        dispatch(showLoading(false));
        navigate(Screens.DASHBOARD);
    }, [userProfile?.organizationId]);

    return (
        <DashboardLayout
            title={""}
            route={[]}
        >
            <h1 className="text-center">404 | Page Not Found</h1>
        </DashboardLayout>
    )
}

export default NotFoundScreen;