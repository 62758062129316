import moment from "moment";
import Swal from "sweetalert2";
import parse from "html-react-parser";

import { v4 as uuidv4 } from "uuid";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

import Constants from "../constants";
import Strings from "../constants/strings";
import ProfileService from "services/identity/profile.service";

import { GroupType } from "constants/enum";
import { ICodename, IMultiLang, IUserAuthorization } from "./interfaces";
import { IResourcePermission, UserInfoState, UserProfile } from "store/slice/userInfo.slice";

const profileService = new ProfileService();

/**
 * Helpers.ts
 *
 * Common function for app.
 */
const Helpers = {
    /**
     * Check value is string or non.
     *
     * @param {any} value: The value to be tested.
     * @returns {boolean} If data type is string true. Otherwise it returns false.
     */
    isString: (value: any): value is string => {
        return typeof value === "string";
    },

    /**
     * Check value is object or non.
     *
     * @param {any} value: The value to be tested.
     * @returns {boolean} If data type is object true. Otherwise it returns false.
     */
    isObject: (value: any): value is object => {
        return typeof value === "object";
    },

    /**
     * Determine if the argument passed is a JavaScript function object.
     *
     * @param {any} obj: Object to test whether or not it is an array.
     * @returns {boolean} returns a Boolean indicating whether the object is a JavaScript function
     */
    isFunction: (value: any): value is (...args: any) => void => {
        return typeof value === "function";
    },

    /**
     * Check a value is number or non, if number then return true, otherwise return false.
     *
     * @param {string} value: Value can check number
     * @returns {boolean} if number then return true, otherwise return false.
     */
    isNumber: (value: any): value is number => {
        return typeof value === "number";
    },

    /**
     * Check a value is NaN or not
     *
     * @param {any} value: Value can check number
     * @returns {boolean} if NaN then return true, otherwise return false.
     */
    isNaN: (value: any): value is number => {
        return isNaN(value);
    },

    /**
     * Check Object is null or String null or empty.
     *
     * @param {object | string} value Object or String
     * @returns {boolean} if null or empty return true, otherwise return false.
     */
    isNullOrEmpty: (value: any): value is undefined | boolean => {
        if (Array.isArray(value) && value.length === 0) {
            return true;
        } else return value === undefined || value === null || value === "";
    },

    /**
     * Trim space character (start, end) of input string.
     *
     * @param {string} value: Value for trim
     * @returns {string} String after trim, space start & end is removed
     */
    trim: (value: string): string => {
        return Helpers.isString(value) ? value.trim() : "";
    },

    /**
     * If value is string return value, otherwise return value.toString
     *
     * @param {string} value: Value
     * @returns {string} String or convert of value to string
     */
    ensureString: (value: any): string => {
        try {
            if (!Helpers.isNullOrEmpty(value)) {
                if (Helpers.isString(value)) {
                    return value;
                } else if (Helpers.isObject(value)) {
                    return JSON.stringify(value);
                } else {
                    return `${value}`;
                }
            }
        } catch (error) {
            return "";
        }
        return "";
    },

    /**
     * Convert size in bytes to KB, MB, GB or TB
     *
     * @param {number} bytes: Size convert
     * @returns {string} Value formatted include unit.
     */
    bytesToSize: (bytes: number): string => {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (Helpers.isNullOrEmpty(bytes) || bytes === 0) {
            return "0 Byte";
        }
        const i = Math.floor(Math.floor(Math.log(bytes) / Math.log(1024)));
        return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
    },

    /**
     * Convert date to string with custom format.
     *
     * @param {number | Date} date Date or Timestamp
     * @param {string} format Format string output
     */
    dateToString: (date: number | Date | undefined, format: string): string => {
        if (Helpers.isNullOrEmpty(date)) {
            return "";
        } else if (Helpers.isNumber(date) && `${date}`.length === 10) {
            return moment.unix(date).format(format);
        } else {
            return moment(date).format(format);
        }
    },

    /**
     * Convert string to date.
     *
     * @param {string} dateString string
     */
    stringToDate: (dateString: string): Date => {
        return new Date(dateString);
    },

    /**
     * Convert string to hex color code.
     *
     * @param {string} inputString string
     */
    stringToColor: (inputString: string) => {
        let hash = 0;
        for (let i = 0; i < inputString.length; i++) {
            hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += ("00" + value.toString(16)).substr(-2);
        }

        return color;
    },

    /**
     * Convert date to unix time.
     *
     * @param {Date} date Date
     */
    dateToUnixTime: (date?: Date): number => {
        if (!Helpers.isNullOrEmpty(date)) {
            return moment(date).unix();
        }
        return 0;
    },

    firstDayOfMonthUnixTime: (date?: Date): number => {
        let dateNow = new Date();
        if (!Helpers.isNullOrEmpty(date)) {
            dateNow = date;
        }

        const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1).getTime() / 1000;

        return firstDay;
    },

    lastDayOfMonthUnixTime: (date?: Date): number => {
        let dateNow = new Date();
        if (!Helpers.isNullOrEmpty(date)) {
            dateNow = date;
        }

        const lastDay = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0).getTime() / 1000;

        return lastDay;
    },

    fromNow: (date: number | Date): string => {
        return moment(date).fromNow();
    },

    /**
     * Get protocal from url.
     * e.g. URL is https://google.com, protocal output is [https:]
     *
     * @param {string} url URL
     * @returns {string} Protocal of URL, if not a URL return empty string
     */
    getProtocolFromURL: (url: string): string => {
        const urlTrim = Helpers.trim(url);
        const index = urlTrim.indexOf("//");
        if (index > -1) {
            return urlTrim.substring(0, index);
        }
        return "";
    },

    /**
     * Format numbers with leading zeros
     *
     * @param {number} num A number
     * @param {number} size Sring output length
     * @returns {string} String format with leading zero
     */
    zeroPad: (num: number, size: number): string => {
        let result = `${num}`;
        while (result.length < size) {
            result = "0" + result;
        }
        return result;
    },

    /**
     * Show alert
     *
     * @param {string} message message for display
     * @param {"warning" | "success" | "error" | "info" | undefined} type type of alert
     */
    showAlert: async (message: string, type?: "warning" | "success" | "error" | "info" | "question", okCallback?: any) => {
        const msg = message;
        const okPress = await Swal.fire({
            customClass: {
                container: "custom-sweetalert2",
                confirmButton: "btn btn-success",
            },
            text: msg,
            icon: type,
            confirmButtonColor: Constants.Styles.PRIMARY_COLOR,
            confirmButtonText: Strings.Common.ACCEPT,
            allowOutsideClick: false,
        });
        if (okPress && okPress.isConfirmed && okCallback && Helpers.isFunction(okCallback)) {
            okCallback();
        }
    },

    /**
     * Show confirm alert
     *
     * @param {string} message message for display
     * @param {function} okCallback callback handle when click ok
     * @param {function} cancelCallback callback handle when click cancel
     */
    showConfirmAlert: async (message: string, okCallback: any, cancelCallback?: any, okButtonMessage?: string, cancelButtonMessage?: string) => {
        const msg = message;
        const okPress = await Swal.fire({
            customClass: {
                container: "custom-sweetalert2",
                confirmButton: "btn btn-success border-10",
                cancelButton: "btn btn-danger border-10",
            },
            text: msg,
            icon: "warning",
            confirmButtonColor: Constants.Styles.PRIMARY_COLOR,
            confirmButtonText: okButtonMessage || Strings.Common.ACCEPT,
            cancelButtonColor: Constants.Styles.SECONDARY_COLOR,
            cancelButtonText: cancelButtonMessage || Strings.Common.CANCEL,
            showCancelButton: true,
            reverseButtons: true,
        });
        if (okPress && okPress.isConfirmed && okCallback && Helpers.isFunction(okCallback)) {
            okCallback();
        } else {
            if (cancelCallback && Helpers.isFunction(cancelCallback)) {
                cancelCallback();
            }
        }
    },

    getBase64: (file: Blob, callback: (result: any) => void) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let result = reader.result;
            if (Helpers.isString(reader.result)) {
                const base64Data = reader.result.split(",");
                result = base64Data.length > 0 ? base64Data[1] : "";
            }
            callback(result);
        };
        reader.onerror = (error) => {
            console.log("Error: ", error);
        };
    },


    isCocCoc: () => {
        const thisWindow: any = window;
        const brands: any[] = thisWindow?.navigator?.userAgentData?.brands || [];
        const indexOfCocCoc = brands?.findIndex((item) => item.brand === Constants.COCCOC_BRAND_NAME);
        return indexOfCocCoc !== -1;
    },

    formatDate: (value?: string | Date | number, format?: string): string => {
        const result = value
            ? moment(value)
                .local()
                .format(format || "DD/MM/YYYY")
            : "";
        return result;
    },

    formatTime: (date?: Date | number): string => {
        let h = "";
        let m = "";
        let s = "";
        if (date) {
            if (Helpers.isNumber(date)) {
                h = "" + new Date(date).getHours();
                m = "" + new Date(date).getMinutes();
                s = "" + new Date(date).getSeconds();
            } else {
                h = "" + date.getHours();
                m = "" + date.getMinutes();
                s = "" + date.getSeconds();
            }
            if (h.length < 2) {
                h = "0" + h;
            }
            if (m.length < 2) {
                m = "0" + m;
            }
            if (s.length < 2) {
                s = "0" + s;
            }
            // return h + ":" + m + ":" + s;
            return h + ":" + m;
        }
        return "";
    },

    stringToHTML: (text: string) => {
        return parse(text);
    },

    handleFormatParams(data: any) {
        const params = new URLSearchParams();
        Object.entries(data).forEach(([key, values]) => {
            if (!Helpers.isNullOrEmpty(values)) {
                if (Array.isArray(values)) {
                    if (values.length > 0) {
                        values.forEach((value) => {
                            if (!Helpers.isNullOrEmpty(value)) {
                                params.append(key, value.toString());
                            }
                        });
                    }
                } else {
                    params.append(key, `${values}`);
                }
            }
        });
        return params.toString();
    },

    handleFormatJSON: (data: any) => {
        const result: any = {};
        Object.entries(data).forEach(([key, values]) => {
            if (!Helpers.isNullOrEmpty(values)) {
                result[key] = values;
            }
        });
        return result;
    },

    isValidEmail: (email: string) => {
        return Constants.RegExp.NEW_EMAIL_ADDRESS.test(email);
    },

    isValidIdCard: (idCard: string) => {
        return Constants.RegExp.ID_CARD.test(idCard);
    },

    getPageNumber: (pageNumber: number, pageSize: number, totalCount: number) => {
        const mathCeil = Math.ceil((totalCount || 0) / pageSize);
        const valTemp = mathCeil > 0 ? mathCeil : 1;
        return pageNumber > valTemp ? valTemp : pageNumber;
    },

    formatCurrency: (number: number | string, decimals?: number) => {
        var a = Number(number)
            .toFixed(decimals || 0)
            .split(".");
        a[0] = a[0].replace(/\d(?=(\d{3})+$)/g, "$&,");
        if (Number(number) < 0) {
            return `${a.join(".")}`;
        } else {
            return a.join(".");
        }
    },

    // Xóa dấu trong chuỗi
    removeAccentsFromStrings: (str: string) => {
        // Gộp nhiều dấu space thành 1 space
        str = str.replace(/\s+/g, " ");
        // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
        str = str.trim();
        // bắt đầu xóa dấu tiếng việt trong chuỗi
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
    },

    // Kiểm tra B có trong A không
    isStringIncludes: (stringA?: any, stringB?: any) => {
        const strA = Helpers.removeAccentsFromStrings(`${stringA}`);
        const strB = Helpers.removeAccentsFromStrings(`${stringB}`);
        return strA.toLocaleLowerCase().includes(strB.toLocaleLowerCase());
    },

    checkSpecialSharacters: (value: any) => {
        return Constants.RegExp.SPECIAL_CHARACTERS.test(value);
    },

    renderExceptionError: (error?: any) => {
        let mesErr = undefined;
        let validationErr = undefined;

        if (error?.message?.responseException?.validationErrors) {
            switch ((error?.message?.responseException?.validationErrors || [])[0].message) {
                case "The Mật khẩu must be at least 6 and at max 100 characters long.":
                    validationErr = "Mật khẩu phải có tối thiểu 6 kí tự";
                    break;
                default:
                    validationErr = (error?.message?.responseException?.validationErrors || [])[0].message;
            }
        }

        if (error?.message?.responseException?.exceptionMessage) {
            switch (error?.message?.responseException?.exceptionMessage) {
                case Constants.ApiCode.INVALID_USERNAME_OR_PASSWORD:
                    mesErr = "Tài khoản hoặc mật khẩu không hợp lệ";
                    break;
                default:
                    mesErr = error?.message?.responseException?.exceptionMessage;
            }
        }

        const message =
            validationErr ||
            mesErr ||
            error?.message?.message ||
            error?.message?.Message ||
            error?.responseException?.exceptionMessage ||
            Strings.Message.ERROR;

        if (message === "Server Error") {
            return Strings.Message.ERROR;
        } else {
            return message;
        }
    },

    setItemInLocalStorage: (storageKey: string, value: any) => {
        localStorage.setItem(storageKey, JSON.stringify(value));
    },

    getItemInLocalStorage: (storageKey: string, defaultValue?: any): any => {
        const value = localStorage.getItem(storageKey);
        if (!Helpers.isNullOrEmpty(value) && value !== "undefined" && value !== "null") {
            return JSON.parse(value);
        } else {
            return defaultValue;
        }
    },

    getUrlParams: (keys: string[]): { [key: string]: string | undefined } => {
        const params = new URLSearchParams(window.location.search);
        let datas: { [key: string]: string | undefined } = {};
        keys.forEach((key) => {
            datas[key] = params.get(key) || undefined;
        });
        return datas;
    },

    renderValueByLanguage: (value?: any, defaultLanguage?: string) => {
        if (Helpers.isNullOrEmpty(value) || Object.keys(value).length === 0) {
            return "";
        } else {
            if (Helpers.isNullOrEmpty(defaultLanguage)) {
                const currentLanguage = Helpers.getItemInLocalStorage(Constants.StorageKeys.LANGUAGE, Constants.DefaultLanguage);
                return Helpers.isNullOrEmpty(value?.[currentLanguage]) ? value?.[Constants.DefaultLanguage] : value?.[currentLanguage];
            } else {
                return value?.[defaultLanguage];
            }
        }
    },

    getDeviceId: async () => {
        try {
            const result = await getCurrentBrowserFingerPrint();
            return result;
        } catch (error) {
            return uuidv4();
        }
    },

    checkMaxLengthTypeNumber: (value: any, maxLength: number) => {
        let result = value.toString().slice(0, maxLength);
        return result;
    },

    setValueByMultiLanguage: (data: any, currentLanguage: string, listLanguage: ICodename[], nameKey: string) => {
        //apply field: name and description
        let dataTemp = { ...data };
        listLanguage.forEach((e) => {
            if (Helpers.isNullOrEmpty(dataTemp[nameKey].value[e.code])) {
                const val = {
                    ...dataTemp[nameKey].value,
                    [e.code]: dataTemp[nameKey].value[currentLanguage],
                };
                dataTemp[nameKey] = { ...dataTemp[nameKey], value: val };
            }
            if (dataTemp.description && Helpers.isNullOrEmpty(dataTemp.description.value[e.code])) {
                const val = {
                    ...dataTemp.description.value,
                    [e.code]: dataTemp.description.value[currentLanguage],
                };
                dataTemp.description = { ...dataTemp.description, value: val };
            }
        });
        return dataTemp;
    },

    formatPhoneNumber: (phoneNumber: string) => {
        if (Helpers.isNullOrEmpty(phoneNumber)) return "";
        return `${phoneNumber}`;
    },

    getDefaultValueMultiLanguage: (data: IMultiLang | undefined, currentLanguage: string) => {
        if (Helpers.isNullOrEmpty(data)) return "";
        if (Helpers.isNullOrEmpty(data.value?.[currentLanguage]) && !Helpers.isNullOrEmpty(data?.value?.[Constants.DefaultLanguage]))
            return data?.value?.[Constants.DefaultLanguage];
        if (Helpers.isNullOrEmpty(data.value?.[currentLanguage])) return "";
        return data.value?.[currentLanguage];
    },

    // yup (npm package) validation
    handleValidationError: (error: any) => {
        let newErrors: { [key: string]: string } = {};

        if (error.name === "ValidationError") {
            error.inner.forEach((e: any) => {
                const path = `${e.path}`.split(".");
                newErrors[path[0]] = e.errors[0];
            });
        }
        return newErrors;
    },
};

export default Helpers;
