import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ModuleMessageTemplate } from "@maysoft/circle-common-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "../../constants";
import DashboardLayout from "layout/dashboardLayout";

import { RootState } from "store";
import { setListPathName } from "store/slice/titleRoute.slice";
import { Mode } from "constants/enum";

const MessageTemplateScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const itemPathName = listPathName.find((el) => el.pathName === Screens.MESSAGE_TEMPLATE_LIST);
    const type = searchParams.get("type");
    const tenantCode = searchParams.get("tenantCode");
    const serviceCode = searchParams.get("serviceCode");
    const searchText = searchParams.get("searchText");

    const [dataRequest, setDataRequest] = useState<any>({
        type,
        searchText,

        totalCount: itemPathName?.totalCount || 0,
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

        tenantCode,
        serviceCode,
    });

    const id: string = searchParams.get("id");
    const modeList = (Screens.MESSAGE_TEMPLATE_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const [mode, setMode] = useState<Mode>(pramsMode || Mode.View);

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(id)) {
            return Strings.MESSAGE_TEMPLATE.TITLE_CREATE_VIEW;
        } else {
            return mode === Mode.Update ? Strings.MESSAGE_TEMPLATE.TITLE_UPDATE_VIEW : Strings.MESSAGE_TEMPLATE.TITLE_DETAIL_VIEW;
        }
    }, [mode, id]);

    const route = useMemo(() => {
        let temp = [
            {
                title: Strings.MESSAGE_TEMPLATE.TITLE_MENU,
                route: Screens.MESSAGE_TEMPLATE_LIST,
            },
        ];

        if (Helpers.isNullOrEmpty(id)) {
            return [...temp, { title: Strings.Common.CREATE_NEW, route: "" }];
        } else {
            return [...temp, { title: mode === Mode.Update ? Strings.Common.UPDATE : Strings.Common.DETAIL, route: "" }];
        }
    }, [mode, id]);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(id)) {
            setMode(Mode.Create);
        } else {
            setMode(pramsMode || Mode.View);
            navigate(Screens.MESSAGE_TEMPLATE_EDIT + `?id=${id}&mode=${pramsMode}`, { replace: true });
        }
    }, [pramsMode, id]);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.MESSAGE_TEMPLATE_LIST);
        navigate(Screens.MESSAGE_TEMPLATE_LIST + (itemP ? itemP.query : ""));
    };

    return (
        <DashboardLayout
            title={modeList ? Strings.MESSAGE_TEMPLATE.TITLE_LIST_VIEW : title}
            route={modeList ? [{ title: Strings.MESSAGE_TEMPLATE.TITLE_MENU, route: "" }] : route}
        >
            <ModuleMessageTemplate
                keyModuleContainer={{
                    list: { pathName: Screens.MESSAGE_TEMPLATE_LIST },
                    detail: { pathName: Screens.MESSAGE_TEMPLATE_EDIT },
                }}

                requestGetPaged={dataRequest}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.MESSAGE_TEMPLATE_LIST, query, totalCount: totalCount }));
                    navigate(Screens.MESSAGE_TEMPLATE_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    setDataRequest((prev: any) => ({ ...prev, tenantCode, serviceCode, searchText, type }));
                    if (!Helpers.isNullOrEmpty(data.id)) {
                        navigate(Screens.MESSAGE_TEMPLATE_EDIT + `?id=${data.id}&mode=${data.mode}`);
                    } else {
                        navigate(Screens.MESSAGE_TEMPLATE_EDIT, {
                            state: { tenantCode: data.tenantCode, serviceCode: data.serviceCode },
                        });
                    }
                }}

                mode={mode}
                idDetail={id}
                onGoBack={handleGoBack}
                onChangeMode={(value) => {
                    setMode(value);
                    if (!Helpers.isNullOrEmpty(id)) {
                        navigate(Screens.MESSAGE_TEMPLATE_EDIT + `?id=${id}&mode=${value}`, { replace: true });
                    }
                }}
                detailInitialData={{
                    // @ts-ignore
                    tenantCode: location.state?.tenantCode,
                    // @ts-ignore
                    serviceCode: location.state?.serviceCode,
                }}
            />
        </DashboardLayout>
    );
};

export default MessageTemplateScreen;
