const Screens = {
    // =========================================================================
    // Auth
    // =========================================================================
    AUTH: "/auth",
    LOGIN: "/auth/login",
    LOGIN_REDIRECT: "/auth/callback",

    NOT_FOUND: "/404",
    RESET_PASSWORD: "/resetPassAuth",
    SECOND_LOGIN: "/auth/second_login",
    FORGOT_PASSWORD: "/auth/forgot-password",

    // =========================================================================
    // Main
    // =========================================================================
    /** / */
    HOME: "/",
    PROFILE: "/profile",
    ADMIN_USER: "/user/list",
    PROFILE_UPDATE_PHONE_NUMBER: "/profile/updatePhoneNumber",

    DASHBOARD: "/dashboard",

    MESSAGE_TEMPLATE_LIST: "/message-template/list",
    MESSAGE_TEMPLATE_EDIT: "/message-template/edit",

    MESSAGE_TEMPLATE_CASE_LIST: "/notifi-template-case/list",
    MESSAGE_TEMPLATE_CASE_EDIT: "/notifi-template-case/edit",

    SETTING_LIST: "/setting/system/list",
    SETTING_EDIT: "/setting/system/edit",

    CODENAME_LIST: "/setting/codename/list",
    CODENAME_EDIT: "/setting/codename/edit",

    TOPIC_LIST: "/topic/list",
    TOPIC_EDIT: "/topic/edit",

    COMMUNITY_LIST: "/community/list",
    COMMUNITY_EDIT: "/community/edit",

    FAQ_LIST: "/faq/list",
    FAQ_EDIT: "/faq/edit",
};

export default Screens;
