import Constants from "../../constants";
import MessageService from "./message.service";

class FaqService extends MessageService {

    public getPaged = async (data: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.FAQ.GET_PAGED + `?${data}`
        });
        return result.data.result;
    }

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.FAQ.GET_DETAIL + `/${id}`,
        });
        return result.data.result;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.FAQ.CREATE,
            data: data
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.FAQ.UPDATE,
            data: data
        });
        return result.data;
    }

    public delete = async (id: string) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.FAQ.DELETE + `/${id}`,
        });
        return result.data;
    }

}

export default FaqService