import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Card, Grid, IconButton, Tabs, Tab, TableContainer, Table, TableRow, TableCell } from "@mui/material";
import { Autocomplete, Box, Button, CustomIcon, DataTableHeadCell, FormField, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "constants/index";
import ServiceService from "services/identity/service.service";
import SystemSettingService from "services/message/systemSetting.service";

import { ICodename, IInput } from "commons/interfaces";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { IDataSettingCode, ISettingCommonProps } from "../edit";
import { ActionRequest, MessageType, Mode } from "constants/enum";

interface ICodeValue {
    id?: string;
    code: IInput;
    value: IInput;
    action: number;
};


const SettingKeyValue = (props: ISettingCommonProps) => {
    const dispatch = useDispatch();

    const serviceService = new ServiceService();
    const systemSettingService = new SystemSettingService();

    const [tabIndex, setTabIndex] = useState<number>(0);
    const [serviceCode, setServiceCode] = useState<IInput>({});
    const [listServiceCode, setListServiceCode] = useState<ICodename[]>([]);
    const [dataSettingCode, setDataSettingCode] = useState<IDataSettingCode>({});

    const [settingKeySMS, setSettingKeySMS] = useState<ICodeValue[]>([]);
    const [settingKeyEmail, setSettingKeyEmail] = useState<ICodeValue[]>([]);
    const [settingKeyNotification, setSettingKeyNotification] = useState<ICodeValue[]>([]);
    // const [settingKeyTopic, setSettingKeyTopic] = useState<ICodeValue[]>([]);

    useEffect(() => {
        (async function () {
            dispatch(showLoading(true));

            let listTemp: ICodename[] = [];
            const resultGetService = await serviceService.getAll();

            resultGetService.forEach((e: any) => {
                listTemp.push({
                    code: e.serviceCode,
                    name: e.serviceName,
                });
            });

            setListServiceCode(listTemp);

            !Helpers.isNullOrEmpty(props.settingCode) &&
                await getBySettingCode(props.settingCode)

            dispatch(showLoading(false));
        })();
    }, [props.settingCode]);

    const getBySettingCode = async (code: any) => {
        try {
            dispatch(showLoading(true));

            const result = await systemSettingService.getBySettingCode(code);

            setDataSettingCode({
                id: result?.id,
                organizationId: result?.organizationId,
                serviceCode: result?.serviceCode,
                settingCode: result?.settingCode,
                settingValue: result?.settingValue,
                updateTime: result?.updateTime,
                status: result?.status,
            });

            if (Helpers.isNullOrEmpty(result?.serviceCode)) {
                setServiceCode({ value: Constants.SERVICE_CODE_DEFAULT });
            } else {
                setServiceCode({ value: result?.serviceCode });
            }

            if (!Helpers.isNullOrEmpty(result?.settingValue)) {

                const dataParse = JSON.parse((`${result?.settingValue}`));

                [MessageType.PushNotification, MessageType.Email, MessageType.SMS].forEach(key => {
                    const valueObj = dataParse[key];
                    if (!Helpers.isNullOrEmpty(valueObj) && Object.keys(valueObj).length > 0) {
                        let newValue: ICodeValue[] = [];

                        Object.keys(valueObj).forEach((k, index) => {
                            newValue.push({
                                id: index + k,
                                code: { value: k },
                                value: { value: valueObj[k] },
                                action: ActionRequest.Update,
                            })
                        });

                        if (key === MessageType.PushNotification) {
                            setSettingKeyNotification(newValue);
                        } else if (key === MessageType.SMS) {
                            setSettingKeySMS(newValue);
                        } else if (key === MessageType.Email) {
                            setSettingKeyEmail(newValue);
                        } else { }
                    }
                });
            }
        } catch (error: any) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    }

    const onChangeValueByType = (type: any, query: { id: string, key: string, newValue: any }) => {
        if (MessageType.PushNotification === type) {
            let dataTemp = [...settingKeyNotification || []];
            const index = dataTemp.findIndex(el => el.id === query.id);
            if (index !== -1) {
                let newItem: any = dataTemp[index];
                newItem[query.key] = { value: query.newValue };
                dataTemp[index] = { ...newItem };
                setSettingKeyNotification(dataTemp);
            }
        }
        if (MessageType.Email === type) {
            let dataTemp = [...settingKeyEmail || []];
            const index = dataTemp.findIndex(el => el.id === query.id);
            if (index !== -1) {
                let newItem: any = dataTemp[index];
                newItem[query.key] = { value: query.newValue };
                dataTemp[index] = { ...newItem };
                setSettingKeyEmail(dataTemp);
            }
        }
        if (MessageType.SMS === type) {
            let dataTemp = [...settingKeySMS || []];
            const index = dataTemp.findIndex(el => el.id === query.id);
            if (index !== -1) {
                let newItem: any = dataTemp[index];
                newItem[query.key] = { value: query.newValue };
                dataTemp[index] = { ...newItem };
                setSettingKeySMS(dataTemp);
            }
        }
    };

    const onAddNewItemByType = (type: any) => {
        const checked = onCheckValidateData(type);
        if (checked) {
            let dataTemp: ICodeValue[] = [];

            if (MessageType.PushNotification === type) {
                dataTemp = [...settingKeyNotification || []];
            } else if (MessageType.Email === type) {
                dataTemp = [...settingKeyEmail || []];
            } else if (MessageType.SMS === type) {
                dataTemp = [...settingKeySMS || []];
            } else { }

            dataTemp.push({
                id: Date.now().toString(),
                code: { value: "" },
                value: { value: "" },
                action: ActionRequest.Create,
            })

            if (MessageType.PushNotification === type) {
                setSettingKeyNotification(dataTemp);
            }
            if (MessageType.Email === type) {
                setSettingKeyEmail(dataTemp);
            }
            if (MessageType.SMS === type) {
                setSettingKeySMS(dataTemp);
            }
        } else {
            dispatch(setDataAlert({ message: Strings.Message.PLEASE_COMPLETE_ALL_INFORMATION, type: "error" }));
        }
    };

    const onDeleteItemByType = (type: any, id: string) => {
        if (MessageType.PushNotification === type) {
            let dataTemp: any = [...settingKeyNotification || []].filter(el => el.id !== id) || [];
            setSettingKeyNotification(dataTemp);
        }
        if (MessageType.Email === type) {
            let dataTemp: any = [...settingKeyEmail || []].filter(el => el.id !== id) || [];
            setSettingKeyEmail(dataTemp);
        }
        if (MessageType.SMS === type) {
            let dataTemp: any = [...settingKeySMS || []].filter(el => el.id !== id) || [];
            setSettingKeySMS(dataTemp);
        }
    };

    const onCheckValidateData = (type: any) => {
        let checked: boolean = true;
        let dataTemp: ICodeValue[] = [];

        if (MessageType.PushNotification === type) {
            dataTemp = [...settingKeyNotification || []];
        } else if (MessageType.Email === type) {
            dataTemp = [...settingKeyEmail || []];
        } else if (MessageType.SMS === type) {
            dataTemp = [...settingKeySMS || []];
        } else { }

        const newDataTemp: ICodeValue[] = dataTemp.map((item) => {
            if (Helpers.isNullOrEmpty(item?.code?.value)) {
                checked = false;
                item["code"] = { error: Strings.Validation.REQUIRED };
            }
            if (Helpers.isNullOrEmpty(item?.value?.value)) {
                checked = false;
                item["value"] = { error: Strings.Validation.REQUIRED };
            }
            return item;
        });

        if (!checked) {
            if (MessageType.PushNotification === type) {
                setSettingKeyNotification(newDataTemp);
            }
            if (MessageType.Email === type) {
                setSettingKeyEmail(newDataTemp);
            }
            if (MessageType.SMS === type) {
                setSettingKeySMS(newDataTemp);
            }
        }

        return checked;
    };

    const handleOnActionPress = async () => {
        if (props.mode === Mode.View) {
            props.onChangeMode(Mode.Update);
        } else {
            let checked = true;
            const isValNotifi = onCheckValidateData(MessageType.PushNotification);
            const isValEmail = onCheckValidateData(MessageType.Email);
            const isValSMS = onCheckValidateData(MessageType.SMS);

            if (Helpers.isNullOrEmpty(serviceCode.value)) {
                setServiceCode({ ...serviceCode, error: Strings.Validation.REQUIRED });
                checked = false;
            }

            if (isValNotifi && isValEmail && isValSMS && checked) {
                let newDataNotifi: any = {};
                let newDataEmail: any = {};
                let newDataSMS: any = {};

                settingKeyNotification.forEach(item => {
                    newDataNotifi = {
                        ...newDataNotifi,
                        [item.code?.value]: item.value?.value,
                    }
                });

                settingKeyEmail.forEach(item => {
                    newDataEmail = {
                        ...newDataEmail,
                        [item.code?.value]: item.value?.value,
                    }
                });

                settingKeySMS.forEach(item => {
                    newDataSMS = {
                        ...newDataSMS,
                        [item.code?.value]: item.value?.value,
                    }
                });

                let settingValueTemp: any = {
                    [MessageType.PushNotification]: newDataNotifi,
                    [MessageType.Email]: newDataEmail,
                    [MessageType.SMS]: newDataSMS,
                };


                try {
                    dispatch(showLoading(true));

                    const newDataSubmit = {
                        id: dataSettingCode?.id,
                        serviceCode: dataSettingCode.serviceCode,
                        settingValue: JSON.stringify(settingValueTemp),
                        settingCode: dataSettingCode?.settingCode,
                        organizationId: (Helpers.isNullOrEmpty(dataSettingCode?.organizationId)
                            || (dataSettingCode?.organizationId === "0"))
                            ? (props?.organizationId || "0")
                            : dataSettingCode?.organizationId,
                    };

                    let resultApi = Helpers.isNullOrEmpty(dataSettingCode.id)
                        ? await systemSettingService.create(newDataSubmit as any)
                        : await systemSettingService.update(newDataSubmit);

                    if (resultApi.statusCode === Constants.ApiCode.SUCCESS) {

                        await getBySettingCode(dataSettingCode.serviceCode);

                        props.onChangeMode(Mode.View);

                        dispatch(setDataAlert({
                            message: Helpers.isNullOrEmpty(dataSettingCode.id)
                                ? Strings.Message.CREATE_SUCCESS
                                : Strings.Message.UPDATE_SUCCESS,
                            type: "success"
                        }));
                    }
                } catch (error) {
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                } finally {
                    dispatch(showLoading(false));
                }
            } else {
                dispatch(setDataAlert({ message: Strings.Message.PLEASE_COMPLETE_ALL_INFORMATION, type: "error" }));
                return;
            }
        }
    }


    const getNameType = (key: any) => {
        let nameType = "";
        switch (key) {
            case MessageType.PushNotification:
                nameType = "Notification";
                break;
            case MessageType.Email:
                nameType = "Email";
                break;
            case MessageType.SMS:
                nameType = "SMS";
                break;
            case MessageType.Topic:
                nameType = "Topic";
                break;
            default:
                break;
        }
        return nameType;
    }

    const renderTableCodeValue = (type: any, data: ICodeValue[]) => (
        <Box m={2}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="button" fontWeight="bold" >{Strings.SETTING_KEY.TYPE}: {getNameType(type)}</Typography>
                {
                    (props.mode !== Mode.View) &&
                    <Button color="info" onClick={() => onAddNewItemByType(type)} >{Strings.Common.ADD_NEW}</Button>
                }
            </Box>
            <Box mt={2}>
                <TableContainer>
                    <Table>
                        <Box component="thead">
                            <TableRow>
                                <DataTableHeadCell width={30} sorted={false}>&ensp;STT</DataTableHeadCell>
                                {
                                    props.mode !== Mode.View &&
                                    <DataTableHeadCell width={60} noPad sorted={false}>&ensp;{Strings.Common.ACTION}</DataTableHeadCell>
                                }
                                <DataTableHeadCell width={150} sorted={false}>{Strings.SETTING_KEY.CODE}</DataTableHeadCell>
                                <DataTableHeadCell width={200} sorted={false}>{Strings.SETTING_KEY.VALUE}</DataTableHeadCell>
                            </TableRow>
                        </Box>
                        {(data.length === 0)
                            ? (
                                <TableRow>
                                    <TableCell colSpan={props.mode === Mode.View ? 2 : 3}>
                                        <Box textAlign="center">
                                            <Typography variant="button" color="secondary" fontWeight="regular">
                                                {Strings.Common.NO_DATA}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                            : data.map((element, index) => (
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Typography variant="caption" fontWeight="medium" color="text">
                                            &ensp;{index + 1}
                                        </Typography>
                                    </TableCell>
                                    {
                                        (props.mode !== Mode.View) &&
                                        <TableCell padding="checkbox">
                                            {
                                                (element.action !== ActionRequest.Update) &&
                                                <IconButton onClick={() => {
                                                    onDeleteItemByType(type, element.id);
                                                }}>
                                                    <CustomIcon iconName="delete" />
                                                </IconButton>
                                            }
                                        </TableCell>
                                    }
                                    <TableCell>
                                        {(props.mode === Mode.View)
                                            ? <Typography variant="caption" fontWeight="medium" color="text">
                                                {element.code?.value}
                                            </Typography>
                                            : <Box width={"100%"}>
                                                <FormField
                                                    required
                                                    maxLength={255}
                                                    mode={props.mode}
                                                    variant="outlined"
                                                    defaultValue={element.code?.value}
                                                    errorMessage={element.code?.error}
                                                    placeholder={Strings.SETTING_KEY.ENTER_CODE}
                                                    disabled={element.action === ActionRequest.Update}
                                                    onBlur={value => {
                                                        onChangeValueByType(type, { id: element.id, key: "code", newValue: value });
                                                    }}
                                                />
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {(props.mode === Mode.View)
                                            ? <Typography variant="caption" fontWeight="medium" color="text">
                                                {element.value?.value}
                                            </Typography>
                                            : <Box width={"100%"}>
                                                <FormField
                                                    required
                                                    maxLength={255}
                                                    mode={props.mode}
                                                    variant="outlined"
                                                    defaultValue={element.value?.value}
                                                    errorMessage={element.value?.error}
                                                    placeholder={Strings.SETTING_KEY.ENTER_VALUE}
                                                    onBlur={value => {
                                                        onChangeValueByType(type, { id: element.id, key: "value", newValue: value });
                                                    }}
                                                />
                                            </Box>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </Table>
                </TableContainer>
            </Box>
        </Box >
    );

    return (
        <Box p={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "end", mb: 2 }}>
                        <Button color={"secondary"} onClick={() => { props.onGoBack() }}>
                            {Strings.Common.GO_BACK}
                        </Button>

                        <Button sx={{ ml: 1 }} color={"dark"} onClick={() => handleOnActionPress()} >
                            {(props.mode === Mode.View) ? Strings.Common.EDIT : Strings.Common.SAVE}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <Box display="flex" flexWrap="wrap" alignItems="center">
                                <Typography variant="h5">{Strings.SETTING_KEY.BASIC_INFO} </Typography>
                            </Box>
                            <Grid container spacing={3} p={2}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        required
                                        isFullRow
                                        mode={props.mode}
                                        data={listServiceCode || []}
                                        defaultValue={serviceCode?.value}
                                        errorMessage={serviceCode?.error}
                                        label={Strings.SETTING_FIREBASE.SERVICE_NAME}
                                        placeholder={Strings.SETTING_FIREBASE.SELECT_SERVICE}
                                        onChange={(val) => {
                                            setServiceCode({ value: val });
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs
                                                value={tabIndex}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                                onChange={(event: any, newValue: number) => {
                                                    let type = MessageType.PushNotification;

                                                    if (tabIndex === 1) {
                                                        type = MessageType.Email;
                                                    } else if (tabIndex === 2) {
                                                        type = MessageType.SMS;
                                                    } else {
                                                        type = MessageType.PushNotification;
                                                    }

                                                    const isVal = onCheckValidateData(type);
                                                    if (isVal) {
                                                        setTabIndex(newValue);
                                                    }
                                                }}
                                            >
                                                {
                                                    ["Notification", "Email", "SMS"].map((label, index: number) => (
                                                        <Tab key={index} label={label} />
                                                    ))
                                                }
                                            </Tabs>
                                        </Box>
                                        {
                                            (tabIndex === 0) &&
                                            renderTableCodeValue(MessageType.PushNotification, settingKeyNotification)
                                        }
                                        {
                                            (tabIndex === 1) &&
                                            renderTableCodeValue(MessageType.Email, settingKeyEmail)
                                        }
                                        {
                                            (tabIndex === 2) &&
                                            renderTableCodeValue(MessageType.SMS, settingKeySMS)
                                        }
                                    </Box >
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SettingKeyValue;