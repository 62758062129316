import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModuleTopic } from "@maysoft/circle-common-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { setListPathName } from "store/slice/titleRoute.slice";

const TopicListScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const itemPathName = listPathName.find((el) => el.pathName === Screens.TOPIC_LIST);

    const [dataRequest] = useState<any>({
        type: searchParams.get("type"),
        searchText: searchParams.get("searchText"),

        totalCount: itemPathName?.totalCount || 0,
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });


    const idDetail: string = searchParams.get("id");
    const modeList = (Screens.TOPIC_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const [mode, setMode] = useState<Mode>(pramsMode || Mode.View);

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(idDetail)) return Strings.TOPIC.TITLE_CREATE_VIEW;
        return mode === Mode.View ? Strings.TOPIC.TITLE_DETAIL_VIEW : Strings.TOPIC.TITLE_UPDATE_VIEW;
    }, [idDetail, mode]);

    const route = useMemo(() => {
        const temp = [{ title: Strings.TOPIC.TITLE_MENU, route: Screens.TOPIC_LIST }];
        if (Helpers.isNullOrEmpty(idDetail)) {
            temp.push({
                title: Strings.Common.CREATE_NEW,
                route: "",
            });
        } else {
            temp.push({
                title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [idDetail, mode]);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            setMode(Mode.Create);
        } else {
            setMode(pramsMode || Mode.View);
            navigate(Screens.TOPIC_EDIT + `?id=${idDetail}&mode=${pramsMode}`, { replace: true });
        }
    }, [idDetail, pramsMode]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.TOPIC_LIST);
        navigate(Screens.TOPIC_LIST + (itemPathName ? itemPathName.query : ""));
    };

    return (
        <DashboardLayout
            title={modeList ? Strings.TOPIC.TITLE_LIST_VIEW : title}
            route={modeList ? [{ title: Strings.TOPIC.TITLE_MENU, route: "" }] : route}
        >
            <ModuleTopic
                keyModuleContainer={{
                    list: { pathName: Screens.TOPIC_LIST },
                    detail: { pathName: Screens.TOPIC_EDIT },
                }}
                requestGetPaged={dataRequest}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.TOPIC_LIST, query, totalCount: totalCount }));
                    navigate(Screens.TOPIC_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    if (!Helpers.isNullOrEmpty(data.id)) {
                        navigate(Screens.TOPIC_EDIT + `?id=${data.id}&mode=${data.mode}`);
                    } else {
                        navigate(Screens.TOPIC_EDIT);
                    }
                }}

                mode={mode}
                idDetail={idDetail}
                onGoBack={handleGoBack}
                onChangeMode={(value) => {
                    setMode(value);
                    if (!Helpers.isNullOrEmpty(idDetail)) {
                        navigate(Screens.TOPIC_EDIT + `?id=${idDetail}&mode=${value}`, { replace: true });
                    }
                }}
            />
        </DashboardLayout>
    );
};

export default TopicListScreen;
