import { useDispatch } from "react-redux";
import { Box, ProfileContainer } from "@maysoft/common-component-react";

import Strings from "constants/strings";
import DashboardLayout from "layout/dashboardLayout";
import { updateUserProfile } from "store/slice/userInfo.slice";

const ProfileScreen = () => {
    const dispatch = useDispatch();

    return (
        <DashboardLayout
            title={Strings.PROFILE.TITLE_MENU}
            route={[{ title: Strings.PROFILE.TITLE_MENU, route: "" }]}
        >
            <Box mt={1}>
                <ProfileContainer
                    isMultiCardContainer={false}
                    hidenCardInfoProfile={{
                        hidenIdCardInfo: true,
                        hidenPassportInfo: true,
                        hidenUserProfileByOrganization: false,
                    }}
                    onDataUserProfile={(data) => {
                        dispatch(updateUserProfile(data as any))
                    }}
                />
            </Box>
        </DashboardLayout>
    );
};

export default ProfileScreen;
