import CodenameScreen from 'screens/codename';
import CommunityScreen from 'screens/community';
import DashboardScreen from 'screens/dashboard';
import FaqListScreen from 'screens/faq';
import FaqEditScreen from 'screens/faq/edit';
import MessageTemplateScreen from 'screens/messageTemplate';
import MessageTemplateCaseScreen from 'screens/messageTemplateCase';
import ProfileScreen from 'screens/profile';
import SettingScreen from 'screens/setting';
import EditSettingScreen from 'screens/setting/edit';
import TopicListScreen from 'screens/topic';



export const useMapScreenName = () => {
    let data: any = {
        "ProfileScreen": <ProfileScreen />,
        "DashboardScreen": <DashboardScreen />,

        "MessageTemplateScreen": <MessageTemplateScreen />,
        "MessageTemplateCaseScreen": <MessageTemplateCaseScreen />,
        "TopicScreen": <TopicListScreen />,
        "CommunityScreen": <CommunityScreen />,

        "FaqListScreen": <FaqListScreen />,
        "FaqEditScreen": <FaqEditScreen />,
        "CodenameScreen": <CodenameScreen />,
        "SettingScreen": <SettingScreen />,
        "EditSettingScreen": <EditSettingScreen />,

    }

    return data;
}

