import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SettingConfigFirebaseContainer } from "@maysoft/circle-common-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import DashboardLayout from "layout/dashboardLayout";
import SettingKeyValue from "./container/setttingKeyValue";

import { RootState } from "store";
import { Mode } from "constants/enum";

export interface IDataSettingCode {
    id?: string;
    tenantCode?: string;
    serviceCode?: string;
    organizationId?: string;

    settingCode?: string;
    settingValue?: string;

    updateTime?: any;
    status?: number;
}

export interface ISettingCommonProps {
    mode?: number;
    settingCode?: string;
    organizationId?: string;

    onGoBack: () => void;
    onChangeMode: (mode: any) => void;
}

const EditSettingScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [model, setModel] = useState<any>({ mode: Mode.View });
    const { settingCode } = Helpers.getUrlParams(["settingCode"]);

    const userProfile = useSelector((state: RootState) => state.userInfo.userProfile);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    useEffect(() => {
        const state: any = location?.state;

        if (!Helpers.isNullOrEmpty(settingCode)) {
            let mode: number = state?.mode ? state?.mode : Mode.Update;
            setModel({
                mode: mode,
                title: mode === Mode.View ? Strings.SETTING.TITLE_DETAIL_VIEW : Strings.SETTING.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.SETTING.SYSTEM_SETTING, route: Screens.SETTING_LIST },
                    {
                        title: mode === Mode.View ? Strings.Common.VIEW : Strings.Common.UPDATE,
                        route: "",
                    },
                ],
            });
        } else {
            setModel({
                mode: Mode.Create,
                title: Strings.SETTING.TITLE_CREATE_VIEW,
                route: [
                    { title: Strings.SETTING.SYSTEM_SETTING, route: Screens.SETTING_LIST },
                    { title: Strings.Common.ADD_NEW, route: "" },
                ],
            });
        }
    }, [settingCode, userProfile?.organizationId]);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.SETTING_LIST);
        navigate(Screens.SETTING_LIST + (itemP ? itemP.query : ""));
    };

    const handleChangeMode = (newMode: any) => {
        if (newMode === Mode.Create) {
            setModel({
                mode: Mode.Create,
                title: Strings.SETTING.TITLE_CREATE_VIEW,
                route: [
                    { title: Strings.SETTING.SYSTEM_SETTING, route: Screens.SETTING_LIST },
                    { title: Strings.Common.ADD_NEW, route: "" },
                ],
            });
        }
        if (newMode === Mode.Update) {
            setModel({
                mode: Mode.Update,
                title: Strings.SETTING.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.SETTING.SYSTEM_SETTING, route: Screens.SETTING_LIST },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            });
        }
        if (newMode === Mode.View) {
            setModel({
                mode: Mode.View,
                title: Strings.SETTING.TITLE_DETAIL_VIEW,
                route: [
                    { title: Strings.SETTING.SYSTEM_SETTING, route: Screens.SETTING_LIST },
                    { title: Strings.Common.DETAIL, route: "" },
                ],
            });
        }
    };

    return (
        <DashboardLayout title={model.title} route={model.route}>
            <>
                {settingCode === "000000" && (
                    <SettingConfigFirebaseContainer
                        mode={model?.mode}
                        listServiceCode={[]}
                        hidenInputServiceCode
                        settingCode={settingCode}
                        onGoBack={() => {
                            handleGoBack();
                        }}
                        onChangeMode={(mode) => {
                            handleChangeMode(mode);
                        }}
                    />
                )}
                {settingCode === "000001" && (
                    <SettingKeyValue
                        mode={model?.mode}
                        settingCode={settingCode}
                        onGoBack={() => {
                            handleGoBack();
                        }}
                        onChangeMode={(mode) => {
                            handleChangeMode(mode);
                        }}
                    />
                )}
            </>
        </DashboardLayout>
    );
};

export default EditSettingScreen;
