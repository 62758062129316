import { RootState } from "store";
import { Grid } from "@mui/material";
import { Info } from "@mui/icons-material";
import { useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Loading, SnackbarAlert, SnackbarMessage, Typography } from "@maysoft/common-component-react";

import Constants from "../constants";
import Screens from "constants/screens";
import Helpers from "../commons/helpers";
import Strings from "../constants/strings";

import { ITitleRoute } from "../commons/interfaces";
import { showLoading } from "store/slice/loadingAPI.slice";
import { updateTitleRoute } from "../store/slice/titleRoute.slice";
import { resetDataAlert, resetDataMessage } from "store/slice/message.slice";

interface IProps {
    isRequiredOrganization?: boolean;
    children: ReactNode;
    title?: string;
    route?: ITitleRoute[];
    onBackPress?: () => void;
    onActionPress?: () => void;
    nameBackPress?: string;
    nameActionPress?: string;
    colorBackPress?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "default";
    colorSubmitPress?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "default";
    listBtn?: ReactNode[];
}

const DashboardLayout: React.FunctionComponent<IProps> = ({
    title, route, children, isRequiredOrganization,
    onBackPress, onActionPress,
    nameBackPress, nameActionPress,
    colorBackPress, colorSubmitPress, listBtn
}: IProps) => {
    const navigate = useNavigate();
    const dispatchRedux = useDispatch();

    const loading = useSelector((state: RootState) => state.loadingAPI.loading);
    const userProfile = useSelector((state: RootState) => state.userInfo.userProfile);
    const dataAlert = useSelector((state: RootState) => state.messageSlice.dataAlert);
    const dataMessage = useSelector((state: RootState) => state.messageSlice.dataMessage);

    useEffect(() => {
        const handleEventNotFound = () => {
            Helpers.showAlert(Strings.Message.NOT_FOUND, "error", () => {
                Helpers.isFunction(onBackPress) ? onBackPress() : navigate(Screens.DASHBOARD);
            });
            dispatchRedux(showLoading(false));
        }

        const handleNotPermission = () => {
            Helpers.showAlert(Strings.Message.NOT_PERMISSION, "error", () => {
                Helpers.isFunction(onBackPress) ? onBackPress() : navigate(Screens.DASHBOARD);
            });
            dispatchRedux(showLoading(false));
        };

        __EventEmitter.addListener(Constants.EventName.NOT_FOUND, handleEventNotFound);
        __EventEmitter.addListener(Constants.EventName.NOT_PERMISSION, handleNotPermission);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.NOT_FOUND, handleEventNotFound);
            __EventEmitter.removeListener(Constants.EventName.NOT_PERMISSION, handleNotPermission);

        }
    }, [navigate])

    useEffect(() => {
        dispatchRedux(updateTitleRoute({
            route: route || [],
            titleScreen: title || "",
        }));
        // lưu path lại
        sessionStorage.setItem(Constants.StorageKeys.FROM, `${window.location.pathname}${window.location.search}`);
    }, [title, route]);

    useEffect(() => {
        return () => {
            dispatchRedux(resetDataAlert());
            dispatchRedux(resetDataMessage());
        }
    }, [dispatchRedux])


    const renderBoxChildren = () => (
        <>
            {
                (Helpers.isFunction(onActionPress) || Helpers.isFunction(onBackPress) || [...listBtn || []].length > 0) &&
                <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "end", mb: 2 }}>
                    {Helpers.isFunction(onBackPress) &&
                        <Button color={colorBackPress || "secondary"} onClick={onBackPress}>
                            {nameBackPress || Strings.Common.GO_BACK}
                        </Button>
                    }
                    {Helpers.isFunction(onActionPress) &&
                        <Button sx={{ ml: 1 }} color={colorSubmitPress || "dark"} onClick={onActionPress} >
                            {nameActionPress || Strings.Common.SAVE}
                        </Button>
                    }
                    {
                        [...listBtn || []].map((item, index: number) => <div key={index}>{item}</div>)
                    }
                </Box>
            }
            {children}
        </>
    )

    return (
        <>
            <Loading visible={loading} />
            <Box
                key={userProfile?.organizationId}
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 1,
                    py: 2,
                    position: "relative",
                    [breakpoints.up("xl")]: {
                        transition: transitions.create(["margin-left", "margin-right"], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                {
                    (isRequiredOrganization === true)
                        ?
                        (!Helpers.isNullOrEmpty(userProfile?.identityId) &&
                            Helpers.isNullOrEmpty(userProfile?.organizationId))
                            ? (
                                <Box sx={{
                                    padding: 1,
                                    marginTop: 2,
                                    display: "flex",
                                    borderRadius: 2.5,
                                    backgroundColor: "#fff",
                                }}>
                                    <Box pr={1} pt="4px">
                                        <Info fontSize="inherit" />
                                    </Box>
                                    <Grid container>
                                        <Grid item xs={12} sm={9} >
                                            <Typography variant="button" fontWeight="bold">
                                                {Strings.Message.DONT_HAVE_ORGANIZATION}
                                                <a className="hyperlink"
                                                    onClick={() => {
                                                        __EventEmitter.emit(Constants.EventName.CHOOSE_ORGANIZATION);
                                                    }}
                                                >{Strings.Common.SELECT.toLowerCase()}</a>
                                                {Strings.Message.CORRESPONDING_ORGANIZATION}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3} >
                                            <Button
                                                color="info"
                                                variant="text"
                                                sx={{ float: "right" }}
                                                onClick={() => {

                                                }}
                                            >{Strings.DASHBOARD.CREATE_ORGANIZATION}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                            : renderBoxChildren()
                        : renderBoxChildren()
                }

                <SnackbarAlert
                    dataAlert={dataAlert as any}
                    onClose={() => {
                        dispatchRedux(resetDataAlert());
                    }}
                />
                <SnackbarMessage
                    dataMessage={dataMessage}
                    onClose={() => {
                        dispatchRedux(resetDataMessage());
                    }}
                />

            </Box>
        </>
    );
}

export default DashboardLayout;
