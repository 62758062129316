import { Add } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, Box, Button, DataTable, DataTableStatusCell, FormField } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "../../constants";
import Screens from "constants/screens";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { Mode, Status } from 'constants/enum';
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from "store/slice/titleRoute.slice";
import FaqService from "services/message/faq.service";
import TopicService from "services/message/topic.service";

interface IRequestGetPaged {
    question?: string;
    answer?: string;
    topicId?: string;
    organizationId?: string;

    totalCount?: number;
    pageNumber?: number;
    pageSize?: number;
    orderby?: string;
    searchText: string;
}

export interface IRecordFaq {
    id?: string;
    question?: string;
    answer?: string;
    topicId?: string;
    topicName?: string;
    organizationId?: string;

    status?: number;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
}

const faqService = new FaqService();
const topicService = new TopicService();

export const dataStatusCell: any = {
    [Status.Active]: { icon: "done", color: "success", title: Strings.Common.ACTIVE },
    [Status.Inactive]: { icon: "clear", color: "secondary", title: Strings.Common.IN_ACTIVE },
}

const FaqListScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const [dataRows, setDataRows] = useState<IRecordFaq[]>([]);
    const [requestData, setRequestData] = useState<IRequestGetPaged>({} as IRequestGetPaged);
    const [topics, setTopics] = useState([]);

    const currentLanguage = Helpers.getItemInLocalStorage(Constants.StorageKeys.LANGUAGE, Constants.DefaultLanguage);

    useEffect(() => {
        getTopics();
    }, []);

    useEffect(() => {
        const item = listPathName.find((el: any) => el.pathName === Screens.FAQ_LIST);
        const newData: any = {
            searchText: searchParams.get("searchText"),
            organizationId: searchParams.get("organizationId"),
            pageNumber: Number(searchParams.get("pageNumber") || 1),
            pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
            totalCount: item?.totalCount,
        };
        getPaged(newData);
    }, [userProfile?.organizationId, currentLanguage]);

    const getTopics = async () => {
        try {
            dispatch(showLoading(true));
            const newData = {
                serviceCode: Constants.SERVICE_CODE_DEFAULT,
                tenantCode: Constants.TENANT_CODE_DEFAULT,
            };

            const result = await topicService.getPaged(Helpers.handleFormatParams(newData));
            const _topics = result.items?.map((item: any) => {
                return {
                    id: item.id,
                    code: item.id,
                    name: item.topicName
                }
            });

            setTopics(_topics);
            dispatch(showLoading(false));
        } catch (error) {
            console.log("error", error)
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const getPaged = async (dataReq?: IRequestGetPaged) => {
        try {
            dispatch(showLoading(true));

            const pageSize = dataReq?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(dataReq?.pageNumber || 1, pageSize, dataReq?.totalCount || 0);

            const newRequest: any = {
                pageSize,
                pageNumber,
                organizationId: dataReq?.organizationId || userProfile?.organizationId || "0",
                topicId: dataReq.topicId,
                searchText: dataReq.searchText || undefined
            };

            const result = await faqService.getPaged(Helpers.handleFormatParams(newRequest));

            let dataTemp: any[] = [...result?.items || []];

            dataTemp = [...result?.items || []].map((item: any) => {
                return {
                    id: item.id,
                    question: item.question?.value[currentLanguage],
                    answer: item.answer?.value[currentLanguage],
                    topicName: item.topicName,
                    status: item.status
                }
            })

            let query: string = `?pageNumber=${ pageNumber }&pageSize=${ pageSize }`;
            if (!Helpers.isNullOrEmpty(newRequest?.searchText)) {
                query += `&searchText=${newRequest?.searchText}`
            }
            
            if (!Helpers.isNullOrEmpty(newRequest?.topicId)) {
                query += `&topicId=${newRequest?.topicId}`
            }

            setDataRows(dataTemp);
            setRequestData({ ...newRequest, pageSize, pageNumber, totalCount: result.totalCount });

            navigate(Screens.FAQ_LIST + query, { replace: true });

            dispatch(setListPathName({ pathname: Screens.FAQ_LIST, query, totalCount: result.totalCount }));
            dispatch(showLoading(false));
        } catch (error) {
            console.log("error", error)
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleDeleteRow = async (id: string) => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_DELETE, async () => {
            try {
                dispatch(showLoading(true));

                const result = await faqService.delete(id);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    await getPaged(requestData);
                }

                dispatch(showLoading(false));
                dispatch(setDataAlert({ message: Strings.Message.DELETE_SUCCESS, type: "success" }));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    const FilterForm = () => (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormField
                        maxLength={255}
                        label={Strings.FAQ.SEARCH_TEXT}
                        placeholder={Strings.FAQ.INPUT_SEARCH_TEXT}
                        defaultValue={requestData?.searchText || ""}
                        onBlur={(value) => setRequestData({ ...requestData, searchText: value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        data={topics || []}
                        label={Strings.TOPIC.NAME}
                        placeholder={Strings.TOPIC.SELECT_TOPIC}
                        defaultValue={requestData.topicId}
                        onChange={(value) => {
                            setRequestData({ ...requestData, topicId: value })
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <DashboardLayout
            title={Strings.FAQ.TITLE_LIST_VIEW}
            route={[{ title: Strings.FAQ.TITLE_MENU, route: "" }]}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Button onClick={() => { navigate(Screens.FAQ_EDIT) }}>
                            <Add />&nbsp;
                            {Strings.Common.ADD_NEW}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                isShowIndex
                                totalCount={requestData?.totalCount}
                                pageNumber={requestData?.pageNumber}
                                rowPerPage={requestData?.pageSize || Constants.ROW_PER_PAGE}
                                onChangePageSize={(pageSize: number) => getPaged({ ...requestData, pageSize })}
                                onChangePageNumber={(pageNumber: number) => getPaged({ ...requestData, pageNumber })}

                                searchText={requestData?.searchText}
                                placeholderSearch={Strings.Common.SEARCH_TEXT}
                                onSearch={(val: string) => {
                                    getPaged({ ...requestData, pageNumber: 1, searchText: val });
                                }}
                                onSearchChange={(val: string) => {
                                    setRequestData({ ...requestData, searchText: val });
                                }}
                                filterForm={<FilterForm />}
                                onFilter={() => getPaged({ ...requestData, pageNumber: 1 })}
                                table={{
                                    columns: [
                                        { Header: Strings.FAQ.QUESTION, accessor: "question" },
                                        { Header: Strings.FAQ.ANSWER, accessor: "answer" },
                                        { Header: Strings.TOPIC.NAME, accessor: "topicName" },
                                        {
                                            Header: Strings.Common.STATUS, accessor: "status",
                                            Cell: ({ value }: any) => <DataTableStatusCell data={dataStatusCell[value]} />
                                        },
                                    ],
                                    rows: dataRows || [],
                                }}

                                actionList={(row: any) => [
                                    (row.status === Status.Active) && {
                                        key: "detail",
                                        iconName: "view",
                                        title: Strings.Common.VIEW_ALL,
                                        callback: (row: any) => navigate(Screens.FAQ_EDIT + `?id=${ row.id }`, { state: { mode: Mode.View } }),
                                    },
                                    (row.status === Status.Active) && {
                                        key: "edit",
                                        iconName: "edit",
                                        title: Strings.Common.EDIT,
                                        callback: (row: any) => navigate(Screens.FAQ_EDIT + `?id=${ row.id }`, { state: { mode: Mode.Update } }),
                                    },
                                    (row.status === Status.Active) && {
                                        key: "delete",
                                        iconName: "delete",
                                        title: Strings.Common.DELETE,
                                        callback: (row: any) => handleDeleteRow(row.id),
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default FaqListScreen;
