import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Constants from "../../constants";
import Screens from "constants/screens";
import LoadingLayout from "layout/loadingLayout";
import { useAuth } from "providers/authProvider";

const LoginRedirectScreen = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        const user = auth.user;
        if (user) {
            const from = sessionStorage.getItem(Constants.StorageKeys.FROM) || Screens.HOME;
            navigate(from, { replace: true });
        } else if (user === null) {
            auth.signinRedirect();
        }
    }, [auth.user])

    return (
        <LoadingLayout />
    );
}

export default LoginRedirectScreen