import Helpers from "commons/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICodename, IRecordMenuDetail } from "commons/interfaces";
import { IUserProfile } from "@maysoft/common-component-react";

export interface IResourcePermission {
    resourceURI: string;
    permission: number;
}
export type UserProfile = IUserProfile;
export interface UserInfoState {
    fetching?: boolean;
    userProfile?: UserProfile;
    currentOrganization?: string;

    resourceMenu?: string[];
    listGroup?: ICodename[];
    listOrganization?: ICodename[];
    menuDetails?: IRecordMenuDetail[];
    resourceCodes?: IResourcePermission[];
}

const initialState: UserInfoState = {
    fetching: false,
    userProfile: undefined,
    currentOrganization: undefined,
    listGroup: [],
    listOrganization: [],
    menuDetails: [],
    resourceMenu: [],
    resourceCodes: [],
};

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        fetchUserInfo: (state) => {
            state.fetching = true;
        },

        fetchUserInfoSuccess: (state, action: PayloadAction<UserInfoState | undefined>) => {
            state.fetching = false;
            if (!Helpers.isNullOrEmpty(action.payload)) {
                state.userProfile = action.payload.userProfile;
                state.listGroup = action.payload.listGroup;
                state.listOrganization = action.payload.listOrganization;
                state.menuDetails = action.payload.menuDetails;
                state.resourceMenu = action.payload.resourceMenu;
                state.resourceCodes = action.payload.resourceCodes;
            }
        },

        fetchUserInfoFailed: (state) => {
            state.fetching = false;
        },

        storePhoneNumber: (state, action: PayloadAction<string>) => {
            state.userProfile.phoneNumber = action.payload;
        },
        storeEmail: (state, action: PayloadAction<string>) => {
            state.userProfile.email = action.payload;
        },
        updateUserProfile: (state, action: PayloadAction<UserProfile>) => {
            state.userProfile = action.payload;
        },
        updateAvatar: (state, action: PayloadAction<any>) => {
            state.userProfile.avatarId = action.payload.avatarId;
            state.userProfile.avatarUrl = action.payload.avatarUrl;
        },
        updateOrganizationId: (state, action: PayloadAction<any>) => {
            state.userProfile.organizationId = action.payload;
        },
        storeListOrganization: (state, action: PayloadAction<ICodename[]>) => {
            state.listOrganization = action.payload;
        },
        storeResourceMenu: (state, action: PayloadAction<string[]>) => {
            state.resourceMenu = action.payload;
        },
        storeResourcePermission: (state, action: PayloadAction<IResourcePermission[]>) => {
            state.resourceCodes = action.payload;
        },
        resetUserInfo: () => initialState,
    },
});

// Action creators are generated for each case reducer function
export const {
    fetchUserInfo,
    fetchUserInfoFailed,
    fetchUserInfoSuccess,
    storeEmail,
    updateAvatar,
    storePhoneNumber,
    updateUserProfile,
    updateOrganizationId,
    storeResourceMenu,
    storeListOrganization,
    storeResourcePermission,
    resetUserInfo,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
