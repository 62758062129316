import APIAccessor from "../apiProcessor"
import { IDENTITY_API_URL } from "../../constants";

class IdentityService {
    protected api: any;

    constructor() {
        this.api = new APIAccessor(IDENTITY_API_URL);
    }
}

export default IdentityService