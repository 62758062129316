// PUBLIC
export enum Status {
    Deleted = -9,
    PendingInvitation = -3,
    Blocked = -2,
    Draft = -1,
    Inactive = 0,
    Active = 1,
    Accept = 2,
    Decline = 3,
}

export enum Gender {
    Male = 1,
    Female = 2,
    Other = 3,
}

export enum IsTrue {
    False = 0,
    True = 1,
}

export enum Mode {
    Create = 0,
    Update = 1,
    View = 2,
}

export enum ActionRequest {
    Create = 0,
    Update = 1,
    Delete = -1,
    Empty = -2,
    Detail = -33,
}

export enum ActionTypeSelectBox {
    Delete = -9,
    Submit = 9,
    Cancel = 99,
}

export enum MultipleApply {
    False = 0,
    True = 1,
}

export enum FileAccessMode {
    Public = 0,
    Authenticated = 1,
    Internal = 2,
    Private = 4,
}

export enum FileProcessingStatus {
    Unprocessed = 0,
    Processing = 1,
    Processed = 2,
}

export enum GroupType {
    Company = 0,
    Department = 1,
    BranchStore = 2,
    Membership = 64,
    Other = 127,
}

export enum ContactDefault {
    None = 0,
    Default = 1,
}

export enum MessageTemplateType {
    Email = 0,
    SMS = 1,
    Notification = 2,
}

export enum AvailableLanguage {
    Vietnamese = "vi",
    English = "en",
}

export enum MessageType {
    PushNotification = "notification",
    SMS = "sms",
    Email = "email",
    Topic = "topic",
}

export enum MessageTemplateCaseCode {
    Email = "MCASE0",
    Notification = "MCASE1",
    SMS = "MCASE2",
}

export enum OrganizationType {
    Normal = 0,
    Main = 1,
}