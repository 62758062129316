import axios from "axios";
import moment from "moment";
import { setLocale } from "yup";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Constants from "./constants";
import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "./constants/screens";
import MainLayout from "./layout/mainLayout";
import NotFoundScreen from "./screens/notFound";
import DashboardScreen from "screens/dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginRedirectScreen from "screens/login/loginRedirect";

import { RootState } from "store";
import { useAuth } from "providers/authProvider";
import { useRenderRoute } from "./routes/routes";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, IRecordMenuDetail } from "commons/interfaces";
import { CircleCommonProvider } from "@maysoft/circle-common-react";
import { CommonComponentProvider, IItemRoute } from "@maysoft/common-component-react";

import "./classes/globals.css";
import "./classes/utilities.css";
import { useMapScreenName } from "routes/mapScreenName";

export const listMultiLanguage: ICodename[] = [
    { code: Constants.LanguageContent.VI, name: Strings.Languages.VI },
    { code: Constants.LanguageContent.EN, name: Strings.Languages.EN },
];

function App() {
    const auth = useAuth();
    const dispatchRedux = useDispatch();

    const [renderKey, setRenderKey] = useState(0);

    const routes = useRenderRoute(renderKey);

    const [menus, setMenus] = useState<IItemRoute[]>(routes);
    const [menuDetails, setMenuDetails] = useState<IRecordMenuDetail[]>([]);

    const dataMapScreenName = useMapScreenName();
    const userInfo = useSelector((state: RootState) => state.userInfo);

    const currentLanguage = Helpers.getItemInLocalStorage(Constants.StorageKeys.LANGUAGE, Constants.DefaultLanguage);

    useEffect(() => {
        moment.locale(currentLanguage);
        setLocale({
            // use constant translation keys for messages without values
            mixed: {
                required: Strings.Validation.REQUIRED,
            },
            string: {
                email: Strings.Validation.EMAIL_ADDRESS,
            },
        });
    }, [currentLanguage]);

    useEffect(() => {
        const setKey = () => {
            setRenderKey(Date.now());
        };

        __EventEmitter.addListener(Constants.EventName.LANGUAGE_CHANGE, setKey);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.LANGUAGE_CHANGE, setKey);
        };
    }, []);

    useEffect(() => {
        if (auth?.user == null) {
            return;
        } else {
            setMenuDetails([...userInfo?.menuDetails || []]);
        }
    }, [auth.user, userInfo?.menuDetails]);

    const renderRoute = useMemo(() => {

        const arrRoute: any[] = [];

        menuDetails.forEach((item, key) => {
            arrRoute.push(
                <Route
                    key={key}
                    path={item.externalUrl}
                    element={dataMapScreenName[item.screenName] ?? <></>}
                />
            );

            if (!Helpers.isNullOrEmpty(item.extraInformation)) {
                const extraInformation = JSON.parse(item.extraInformation);
                if (Array.isArray(extraInformation)) {
                    extraInformation.forEach((el) => {
                        arrRoute.push(
                            <Route
                                key={key}
                                path={el.ScreenPath || el.screenPath}
                                element={dataMapScreenName[el.ScreenName || el.screenName] ?? <NotFoundScreen />}
                            />
                        );
                    });
                } else {
                    arrRoute.push(
                        <Route
                            key={key}
                            path={extraInformation?.ScreenPath || extraInformation?.screenPath}
                            element={dataMapScreenName[extraInformation?.ScreenName || extraInformation?.screenName] ?? <NotFoundScreen />}
                        />
                    );
                }
            }
        });

        return arrRoute;
    }, [dataMapScreenName, menuDetails]);

    const renderMainLayout = useMemo(() => (
        <MainLayout routes={menus} menuDetail={menuDetails} />
    ), [menus, menuDetails]);

    return (
        <CommonComponentProvider
            value={{
                axios: axios as any,
                userInfo: userInfo,
                language: currentLanguage,
                clientId: Constants.CLIENT_ID,
                dataMapScreenName: dataMapScreenName,
                listMultiLanguage: listMultiLanguage,
                tenantCode: Constants.TENANT_CODE_DEFAULT,
                serviceCode: Constants.SERVICE_CODE_DEFAULT,
                organizationId: userInfo?.currentOrganization || userInfo?.userProfile?.organizationId,
                onShowLoading() {
                    dispatchRedux(showLoading(true));
                },
                onHideLoading() {
                    dispatchRedux(showLoading(false));
                },
                onSuccess(msg) {
                    dispatchRedux(setDataAlert({ message: msg, type: "success" }));
                },
                onError(msg) {
                    dispatchRedux(showLoading(false));
                    dispatchRedux(setDataAlert({ message: msg, type: "error" }));
                },
            }}
        >
            <CircleCommonProvider>
                <BrowserRouter key={renderKey}>
                    <Routes>
                        <Route path={Screens.LOGIN_REDIRECT} element={<LoginRedirectScreen />} />
                        <Route path={Screens.HOME} element={<ProtectedRoute>{renderMainLayout}</ProtectedRoute>}>
                            <Route index element={<DashboardScreen />} />
                            {renderRoute}
                            <Route path="*" element={<NotFoundScreen />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </CircleCommonProvider>
        </CommonComponentProvider>
    );
}

export default App;
