import APIAccessor from "../apiProcessor";
import { MESSAGE_API_URL } from "constants/index";

class MessageService {
    protected api: APIAccessor;

    constructor() {
        this.api = new APIAccessor(MESSAGE_API_URL);
    }
}

export default MessageService;
