import Helpers from "commons/helpers";
import Constants from "../../constants";
import ProfileService from "services/identity/profile.service";

import { call, takeLatest, put } from "redux-saga/effects";
import { RoleLevel, RoleType } from "@maysoft/common-component-react";
import { ICodename, IOrganizationUserProfile, IUserAuthorization, IUserInfo } from "commons/interfaces";
import { UserInfoState, UserProfile, fetchUserInfo, fetchUserInfoFailed, fetchUserInfoSuccess } from "store/slice/userInfo.slice";
import { OrganizationType } from "constants/enum";

const profileService = new ProfileService();

const ROLE_DEFAULT = RoleType.Normal | RoleType.Member;

function* fetchingUser() {
    try {
        // const oldState: UserInfoState = yield select((state) => state.userInfo);
        // if (oldState.userProfile) {
        //     yield put(fetchUserInfoSuccess(undefined));
        //     return;
        // }

        let organizationId = Helpers.getItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, "") as string;

        const resultProfile: IUserInfo = yield call(profileService.getUserInfo);

        let organizationType = OrganizationType.Normal;
        const currentServiceOrganizations = [...resultProfile?.currentServiceOrganizations || []].filter(el => (el.tenantCode === Constants.TENANT_CODE_DEFAULT)) || [];

        if (currentServiceOrganizations.length > 0) {
            const itemFirst = currentServiceOrganizations?.[0];
            const index = currentServiceOrganizations?.findIndex((el) => el.id === organizationId);
            if (index === -1 && organizationId !== "0") {
                organizationId = itemFirst?.id;
                organizationType = itemFirst?.type;
            }
        } else {
            organizationId = "0";
        }

        const userAuthorization: IUserAuthorization = yield call(profileService.getUserAuthorization, (organizationId === "0") ? undefined : organizationId);

        const itemUserAuthorizationResponse = [...userAuthorization?.userAuthorizationResponse || []].reduce((prev, curr) => {
            return (prev.roleLevel < curr.roleLevel) ? prev : curr;
        }, {
            roleCode: "",
            roleName: "",
            roleType: ROLE_DEFAULT,
            roleLevel: RoleLevel.Default,
        });

        let itemOrganizationUserProfile: IOrganizationUserProfile = [...resultProfile.organizationUserProfiles || []]?.find((item) => (item.organizationId === organizationId));

        let currency: string = undefined;

        const listOrganization = currentServiceOrganizations?.map((e) => {
            if (e.id === organizationId) { currency = e.currency };

            return {
                code: e.id,
                name: Helpers.renderValueByLanguage(e.name?.value) || "",
                detail: {
                    gene: e.gene,
                    organizationCode: e.organizationCode,
                },
            } as ICodename;
        });

        const listGroup: ICodename[] = [...resultProfile.groupUsers || []]?.map((item) => {
            return {
                code: item.groupId,
                name: Helpers.renderValueByLanguage(item.groupName?.value) || "",
                detail: {
                    gene: item.groupGene,
                    group: item.organizationId,
                },
            } as ICodename;
        });

        if (Helpers.isNullOrEmpty(currency)) {
            currency = resultProfile?.defaultCurrency || Constants.CURRENCY_DEFAULT;
        };

        const userProfile: UserProfile = {
            id: resultProfile?.userProfile?.id || itemOrganizationUserProfile?.id || "",
            organizationId: (organizationId === "0") ? undefined : organizationId,
            organizationType: organizationType,
            groupId: "",

            roleCode: itemUserAuthorizationResponse?.roleCode || "",
            roleName: itemUserAuthorizationResponse?.roleName || "",
            roleType: itemUserAuthorizationResponse?.roleType ?? ROLE_DEFAULT,
            roleLevel: itemUserAuthorizationResponse?.roleLevel ?? RoleLevel.Default,

            userName: resultProfile?.userProfile?.userName || "",
            avatarId: resultProfile?.userProfile?.avatarId || "",
            avatarUrl: resultProfile?.userProfile?.avatarUrl || "",
            identityId: resultProfile?.userProfile?.identityId || "",
            email: resultProfile?.userProfile?.email || itemOrganizationUserProfile?.email || "",
            gender: resultProfile?.userProfile?.gender || itemOrganizationUserProfile?.gender || 0,
            fullName: resultProfile?.userProfile?.fullName || itemOrganizationUserProfile?.firstName || "",
            birthDate: resultProfile?.userProfile?.birthDate || itemOrganizationUserProfile?.dateOfBirth || "",
            phoneNumber: resultProfile?.userProfile?.phoneNumber || itemOrganizationUserProfile?.phoneNumber || "",

            status: resultProfile?.userProfile?.status || itemOrganizationUserProfile?.activeStatus || 0,

            currency: currency,
        };

        const resourceMenu = [...userAuthorization?.menus || []];
        const menuDetails = [...userAuthorization?.menuDetails || []];

        const resourceCodes = [...userAuthorization?.roleResourcePermissions || []]?.map((item) => ({ resourceURI: item.resourceURI, permission: item.permission }));

        const result: UserInfoState = {
            userProfile,

            listGroup,
            listOrganization,
            currentOrganization: organizationId || "",

            menuDetails,
            resourceMenu,
            resourceCodes,
        };

        Helpers.setItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, organizationId || "");

        yield put(fetchUserInfoSuccess(result));
    } catch (error) {
        console.error(error);
        yield put(fetchUserInfoFailed());
    }
}

export default function* userInfoSaga() {
    yield takeLatest(fetchUserInfo().type, fetchingUser);
}
