import colors from "assets/theme/base/colors"
const { gradients } = colors;

type Types = any;

const tabs: Types = {
    styleOverrides: {
        root: {
            border: 0,
            fontWeight: 700,
            minHeight: "35px",
            fontSize: "0.7rem",
            padding: "6px 12px",
            "&:hover": {
                border: 0,
                borderColor: "#7b809a",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
            },
            "&.Mui-selected": {
                color: "#fff",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderColor: gradients.dark.main,
                backgroundColor: gradients.dark.main,
            }
        },
    },
};

export default tabs;
