import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, DataTable } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "../../constants";
import DashboardLayout from "layout/dashboardLayout";
import SystemSettingService from "services/message/systemSetting.service";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";

interface ISettingCode {
    id?: string;
    name?: string;
    settingCode?: string;
    description?: string;
}

const systemSettingService = new SystemSettingService();

const SettingScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userProfile = useSelector((state: RootState) => state.userInfo.userProfile);

    const [dataRows, setDataRows] = useState<any[]>([]);

    useEffect(() => { getPaged() }, [userProfile?.organizationId]);

    const getPaged = async () => {
        try {
            dispatch(showLoading(true));

            const result = await systemSettingService.getSystemSetting(["000000", "000001"]);

            const rows: ISettingCode[] = [];
            [...result || []].forEach((element: ISettingCode) => {
                const str: string = Strings.SETTING[`SETTING_CODE_${element.settingCode}`];
                rows.push({
                    ...element,
                    name: str,
                    description: str,
                });
            });

            setDataRows(rows);

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    };

    return (
        <DashboardLayout
            title={Strings.SETTING.SYSTEM_SETTING_LIST}
            route={[{ title: Strings.SETTING.SYSTEM_SETTING, route: "" }]}
        >
            <Box>
                <Card sx={{ mt: 3 }}>
                    <Box p={2}>
                        <DataTable
                            isShowIndex
                            pageNumber={1}
                            totalCount={dataRows.length || 0}
                            rowPerPage={Constants.ROW_PER_PAGE}

                            table={{
                                columns: [
                                    { Header: Strings.SETTING.CODE, accessor: "settingCode", width: "100px" },
                                    { Header: Strings.SETTING.NAME, accessor: "name", width: "150px" },
                                    { Header: Strings.SETTING.DESCRIPTION, accessor: "description", width: "200px" },
                                ],
                                rows: dataRows || [],
                            }}

                            actionList={(row) => [
                                {
                                    key: "detail",
                                    iconName: "view",
                                    title: Strings.Common.VIEW_ALL,
                                    callback: (row) => navigate(Screens.SETTING_EDIT + `?settingCode=${row.settingCode}`, { state: { mode: Mode.View } }),
                                },
                                {
                                    key: "edit",
                                    iconName: "edit",
                                    title: Strings.Common.EDIT,
                                    callback: (row) => navigate(Screens.SETTING_EDIT + `?settingCode=${row.settingCode}`, { state: { mode: Mode.Update } }),
                                },
                            ]}
                        />
                    </Box>
                </Card>
            </Box>
        </DashboardLayout>
    );
};

export default SettingScreen;
