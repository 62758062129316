const ASSET_API_URL = process.env.REACT_APP_ASSET_API_URL || "";
const COMMON_API_URL = process.env.REACT_APP_COMMON_API_URL || "";
const MESSAGE_API_URL = process.env.REACT_APP_MESSAGE_API_URL || "";
const IDENTITY_URL = process.env.REACT_APP_IDENTITY_URL || "";
const IDENTITY_API_URL = `${IDENTITY_URL}/api`;

export { IDENTITY_URL, IDENTITY_API_URL, ASSET_API_URL, COMMON_API_URL, MESSAGE_API_URL }

const Constants = {
    Api: {
        TIMEOUT: 60 * 1000,
    },

    /**
     * Return code from Api
     */
    ApiCode: {
        // Code from server api
        SUCCESS: 200,
        BAD_REQUEST: 400,
        TOKEN_EXPRIED: 401,
        NOT_PERMISSION: 403,
        NOT_FOUND: 404,
        INTERNAL_SERVER_ERROR: 500,

        // Code from local app
        INTERNAL_SERVER: "INTERNAL_SERVER",
        UNKNOWN_NETWORK: "UNKNOWN_NETWORK",
        CONNECTION_TIMEOUT: "CONNECTION_TIMEOUT",
        INVALID_USERNAME_OR_PASSWORD: "invalid_username_or_password"
    },

    /**
     * Cloud Storage config
     */
    CloudStorage: {
        AVATAR_FOLDER: "avatar",
        TEMP_FOLDER: "temp",
        ORGANIZATION_IMAGE_FOLDER: "org_image",
        EDITOR_CONTENT_FOLDER: "editor_content",
    },

    RedirectPath: {
        REDIRECT_URI: "/auth/callback",
        POST_LOGOUT_REDIRECT_URL: "/",
    },

    CURRENCY_DEFAULT: "VND",
    /**
     * Setting path for Api
     */
    ApiPath: {
        /**  * Api path IDENTITY */
        // PROFILE
        PROFILE: {
            USER_INFO: "Profile/UserInfo",
            USER_AUTHORIZATION: "Profile/UserAuthorization",
        },

        SERVICE: {
            GET_ALL: "/Service/GetAll",
        },

        SYSTEM_SETTING: {
            CREATE: "/SystemSetting/Create",
            UPDATE: "/SystemSetting/Update",
            UPDATE_MULTI: "/SystemSetting/UpdateMulti",
            GET_SYSTEM_SETTING: "/SystemSetting/GetSystemSetting",
            GET_BY_SETTING_CODE: "/SystemSetting/GetBySettingCode",
            GET_BY_SETTING_CODES: "/SystemSetting/GetBySettingCodes",
        },

        CODENAME: {
            GET_BY_GROUP: "/Codename/GetByGroup",
            GET_PAGED: "/Codename/GetPaged",
            GET_DETAIL: "/Codename/Detail",
            CREATE: "/Codename/Create",
            UPDATE: "/Codename/Update",
            DELETE: "/Codename/Delete",
        },

        FAQ: {
            GET_PAGED: "/Faq/GetPaged",
            GET_DETAIL: "/Faq/Detail",
            CREATE: "/Faq/Create",
            UPDATE: "/Faq/Update",
            DELETE: "/Faq/Delete",
        },

        TOPIC: {
            GET_PAGED: "/Topic/GetPaged",
            GET_DETAIL: "/Topic/Detail",
            CREATE: "/Topic/Create",
            UPDATE: "/Topic/Update",
            DELETE: "/Topic/Delete",
        },

    },


    /**
     * Styles for app.
     *
     * Color refer
     * @see https://www.rapidtables.com/web/color/index.html
     * @see https://www.w3schools.com/w3css/w3css_colors.asp
     */
    Styles: {
        // =====================================================================
        // Common color
        // =====================================================================
        BLUE_COLOR: "#3b82f6",
        RED_COLOR: "#dc2626",
        GRAY_COLOR: "#808080",
        BLACK_COLOR: "#000000",
        GREEN_COLOR: "#008000",
        WHITE_COLOR: "#FFFFFF",
        PRIMARY_COLOR: "#222224",
        SECONDARY_COLOR: "#7b809a",
        OCEAN_BLUE_COLOR: "#174291",
        LIGHT_BLUE_COLOR: "#12B7BC",
        LIGHT_GRAY_COLOR: "#e5e7eb",
        BRIGHT_OCEAN_BLUE_COLOR: "#1F5DC6",
        PRIMARY_DARK_COLOR: "rgb(13, 142, 147)",

        // New - Analysis - Processing - Processed - Cancelled - Close
        STATUS_COLOR: [
            "#27AE60",
            "#FEC600",
            "#24EBC7",
            "#00AFF0",
            "#D3D3D3",
            "#CED4DA",
        ],

        // =====================================================================
        // Console log style
        // Color refer at: https://www.w3schools.com/w3css/w3css_colors.asp
        // =====================================================================
        CONSOLE_LOG_DONE_ERROR: "border: 2px solid #F44336; color: #000000", // Red
        CONSOLE_LOG_DONE_SUCCESS: "border: 2px solid #4CAF50; color: #000000", // Green
        CONSOLE_LOG_ERROR: "background: #F44336; color: #FFFFFF", // Red
        CONSOLE_LOG_NOTICE: "background: #FF9800; color: #000000; font-size: x-large", // Orange
        CONSOLE_LOG_PREPARE: "border: 2px solid #2196F3; color: #000000", // Blue
        CONSOLE_LOG_START: "background: #2196F3; color: #FFFFFF", // Blue
        CONSOLE_LOG_SUCCESS: "background: #4CAF50; color: #FFFFFF", // Green

        // =====================================================================
        // Common size
        // =====================================================================
        AVATAR_SIZE: "80px",
        DEFAULT_FONTSIZE: "16px",
        DEFAULT_SPACING: "24px",

        // =====================================================================
        // Font size
        // =====================================================================
        FONT_SIZE_SMALL: 13,
        FONT_SIZE_DEFAULT: 15,
        FONT_SIZE_MEDIUM: 18,
        FONT_SIZE_LARGE: 24,
        FONT_SIZE_XLARGE: 27,
        FONT_SIZE_XXLARGE: 32,

        BOX_SHADOW: 3,
        BOX_BORDER_RADIUS: 6,

        COLOR_LAYOUT: {
            BACKGROUND_COLOR_ROOT: "#f0f2f5",
            COLOR_TEXT_ITEM_MENU: "#ffffff",
            COLOR_ITEM_MENU_ACTIVE: "#0f2c60",
            COLOR_ITEM_MENU_ACTIVE_PARENT: "#163978",
        }
    },

    /**
     * Regex Expression
     */
    RegExp: {
        /** https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript */
        EMAIL_ADDRESS: new RegExp(`/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@`
            + `((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`),
        NEW_EMAIL_ADDRESS: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        /** https://gist.github.com/HarishChaudhari/0dd5514ce430991a1b1b8fa04e8b72a4 */
        ID_CARD: new RegExp(/^[0-9]{8,12}$/),
        PHONE_NUMBER: new RegExp(/^0([135789]{1})([0-9]{8})$/),
        SPECIAL_CHARACTERS: new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
        PASSWORD: new RegExp(`/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/`),
    },

    /**
     * Storage keys
     */
    StorageKeys: {
        LANGUAGE: "LANGUAGE",
        ORGANIZATION_ID: "ORGANIZATION_ID",
        FROM: "from",
    },

    EventName: {
        TOKEN_EXPIRED: "TOKEN_EXPIRED",
        NOT_PERMISSION: "NOT_PERMISSION",
        PROFILE_NOT_FOUND: "PROFILE_NOT_FOUND",
        LANGUAGE_CHANGE: "LANGUAGE_CHANGE",
        CHOOSE_ORGANIZATION: "CHOOSE_ORGANIZATION",
        NOT_FOUND: "NOT_FOUND",
    },

    /**
     * Cookie keys
     */
    CookieNames: {
        LANGUAGE: "lang",
        SESSION_ID: "sessionId"
    },

    /**
     * Row per paged
     */
    ROW_PER_PAGE: 20,
    ROW_PER_PAGE_5: 5,
    ROW_PER_PAGE_10: 10,
    ROW_PER_PAGE_20: 20,
    ROW_PER_PAGE_25: 25,
    ROW_PER_PAGE_50: 50,
    ROW_PER_PAGE_100: 100,
    ROW_PER_PAGE_OPTIONS: [20, 50, 100],
    ROW_PER_PAGE_OPTIONS_V1: [10, 25, 50],
    ROW_PER_PAGE_OPTIONS_TEST: [5, 10, 20],

    /**
    * format datetime
    */
    DateFormat: {
        DDMM: "DD/MM",
        MMYYYY: "MM/YYYY",
        DDMMYYYY: "DD/MM/YYYY",
        DDMMYYYY_HHMM: "DD/MM/YYYY HH:mm",
        HHMM_DDMMYYYY: "HH:mm DD/MM/YYYY",
        HHMM: "HH:mm",
        YYYY: "YYYY",
        YYYYMM: "YYYY/MM",
        YYYYMMDD: "YYYY/MM/DD",
        YYYYMMDD_HHMM: "YYYY/MM/DD HH:mm",
        DDDD: "dddd",
    },

    MomentWeekDay: {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
    },

    DefaultLanguage: "vi",
    LanguageContent: {
        VI: "vi",
        EN: "en",
    },
    COCCOC_BRAND_NAME: "CocCoc",

    /**
    * Resources
    */
    ResourceCode: {
        DASHBOARD: "circle/dashboard",
        PROFILE: "circle/profile",

        TOPIC: "circle/topic",
        SYSTEM_SETTING: "circle/system_setting",
        FAQ: "circle/faq",
        COMMUNITY: "circle/dashboard",
    },

    // System Setting Code
    TENANT_ID_DEFAULT: "0",
    TENANT_CODE_DEFAULT: "000000",
    SERVICE_CODE_DEFAULT: "000007",
    CLIENT_ID: process.env.REACT_APP_DEFAULT_CLIENT_ID as string,

    ReduxSerializableCheck: {
        SET_TOKEN_INFO: "tokenInfo/setTokenInfo"
    },
};

export default Constants;
