import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "../../constants";
import Screens from "../../constants/screens";
import LoadingLayout from "layout/loadingLayout";

import { RootState } from "store";
import { useAuth } from "providers/authProvider";

interface IProps {
    children: React.ReactElement
}

const ProtectedRoute: React.FC<IProps> = (props: IProps) => {
    const auth = useAuth();
    const location = useLocation();
    const userInfo = useSelector((state: RootState) => state.userInfo);

    if (auth?.loading) {
        return <LoadingLayout />
    }

    if (auth.user === null) {
        sessionStorage.setItem(Constants.StorageKeys.FROM, location.pathname);
        return <Navigate to={Screens.LOGIN_REDIRECT} replace />
    }

    return !Helpers.isNullOrEmpty(userInfo?.userProfile?.id) ? props.children : <LoadingLayout />;
}

export default ProtectedRoute;