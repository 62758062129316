import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@maysoft/common-component-react";

import Screens from "constants/screens";
import Strings from "constants/strings";
import DashboardLayout from "../../layout/dashboardLayout";

const DashboardScreen = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === "/") {
            navigate(Screens.DASHBOARD, { replace: true });
        }
    }, []);

    return (
        <DashboardLayout
            title={Strings.DASHBOARD.TITLE_MENU}
            route={[{ title: Strings.DASHBOARD.TITLE_MENU, route: "" }]}
        >
            <Box></Box>
        </DashboardLayout>
    );
};


export default DashboardScreen;
