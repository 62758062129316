import { useEffect, useState } from "react";
import { IItemRoute } from "@maysoft/common-component-react";
import { Dashboard, SettingsOutlined, Description, Folder, Topic, QuestionAnswer, Diversity3 } from "@mui/icons-material";

import Constants from "../constants";
import Strings from "constants/strings";
import Screens from "constants/screens";

import ProfileScreen from "screens/profile";
import DasboardScreen from "screens/dashboard";

import TopicScreen from "screens/topic";
import CodenameScreen from "screens/codename";
import CommunityScreen from "screens/community";
import MessageTemplateScreen from "screens/messageTemplate";
import MessageTemplateCaseScreen from "screens/messageTemplateCase";

import SettingScreen from "screens/setting";
import EditSettingScreen from "screens/setting/edit";

import FaqListScreen from "screens/faq";
import FaqEditScreen from "screens/faq/edit";

export const useRenderRoute = (key: any) => {
    const [routes, setRoutes] = useState<IItemRoute[]>([]);

    useEffect(() => {
        const mainRoutes: IItemRoute[] = [
            {
                isVisible: true,
                key: "dashboard",
                icon: <Dashboard />,
                screenPath: Screens.DASHBOARD,
                screenName: <DasboardScreen />,
                title: Strings.DASHBOARD.TITLE_MENU,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },
            {
                key: "profile",
                isVisible: false,
                icon: <Dashboard />,
                screenPath: Screens.PROFILE,
                screenName: <ProfileScreen />,
                title: Strings.PROFILE.TITLE_MENU,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },

            // Message template
            {
                isVisible: true,
                icon: <Description />,
                key: "message-template",
                screenName: <MessageTemplateScreen />,
                screenPath: Screens.MESSAGE_TEMPLATE_LIST,
                title: Strings.MESSAGE_TEMPLATE.TITLE_MENU,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },
            {
                isVisible: false,
                key: "message-tempalte_edit",
                screenName: <MessageTemplateScreen />,
                screenPath: Screens.MESSAGE_TEMPLATE_EDIT,
                title: Strings.MESSAGE_TEMPLATE.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },

            // Message template case
            {
                isVisible: true,
                icon: <Folder />,
                key: "notifi-template-case",
                screenName: <MessageTemplateCaseScreen />,
                screenPath: Screens.MESSAGE_TEMPLATE_CASE_LIST,
                title: Strings.MESSAGE_TEMPLATE_CASE.TITLE_MENU,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },
            {
                isVisible: false,
                key: "notifi-template-case_edit",
                screenName: <MessageTemplateCaseScreen />,
                screenPath: Screens.MESSAGE_TEMPLATE_CASE_EDIT,
                title: Strings.MESSAGE_TEMPLATE_CASE.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },

            // Topic
            {
                isVisible: true,
                icon: <Topic />,
                key: "topic",
                screenName: <TopicScreen />,
                screenPath: Screens.TOPIC_LIST,
                title: Strings.TOPIC.TITLE_MENU,
                resourceCode: Constants.ResourceCode.TOPIC,
            },
            {
                isVisible: false,
                key: "topic_edit",
                screenName: <TopicScreen />,
                screenPath: Screens.TOPIC_EDIT,
                title: Strings.TOPIC.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceCode.TOPIC,
            },

            // Community
            {
                isVisible: true,
                icon: <Diversity3 />,
                key: "community",
                screenName: <CommunityScreen />,
                screenPath: Screens.COMMUNITY_LIST,
                title: Strings.COMMUNITY.TITLE_MENU,
                resourceCode: Constants.ResourceCode.COMMUNITY,
            },
            {
                isVisible: false,
                key: "community_edit",
                screenName: <CommunityScreen />,
                screenPath: Screens.COMMUNITY_EDIT,
                title: Strings.COMMUNITY.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceCode.COMMUNITY,
            },
            // FAQ
            {
                isVisible: true,
                icon: <QuestionAnswer />,
                key: "faq",
                screenName: <FaqListScreen />,
                screenPath: Screens.FAQ_LIST,
                title: Strings.FAQ.TITLE_MENU,
                resourceCode: Constants.ResourceCode.FAQ,
            },
            {
                isVisible: false,
                key: "faq_edit",
                screenName: <FaqEditScreen />,
                screenPath: Screens.FAQ_EDIT,
                title: Strings.FAQ.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceCode.FAQ,
            },

            // Setting Common
            {
                key: "setting",
                isVisible: true,
                icon: <SettingsOutlined />,
                title: Strings.SETTING.TITLE_MENU,
                resourceCode: [
                    Constants.ResourceCode.SYSTEM_SETTING,
                    Constants.ResourceCode.DASHBOARD,
                ],
                subMenu: [
                    // codename
                    {
                        isVisible: true,
                        key: "codename_list",
                        screenName: <CodenameScreen />,
                        screenPath: Screens.CODENAME_LIST,
                        title: Strings.CODE_NAME.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.DASHBOARD,
                    },
                    {
                        isVisible: false,
                        key: "codename_edit",
                        screenPath: Screens.CODENAME_EDIT,
                        title: Strings.CODE_NAME.TITLE_MENU,
                        screenName: <CodenameScreen />,
                        resourceCode: Constants.ResourceCode.DASHBOARD,
                    },

                    // system
                    {
                        isVisible: true,
                        key: "system_list",
                        screenName: <SettingScreen />,
                        screenPath: Screens.SETTING_LIST,
                        title: Strings.SETTING.SYSTEM_SETTING,
                        resourceCode: Constants.ResourceCode.SYSTEM_SETTING,
                    },
                    {
                        isVisible: false,
                        key: "system_edit",
                        screenPath: Screens.SETTING_EDIT,
                        screenName: <EditSettingScreen />,
                        title: Strings.SETTING.SYSTEM_SETTING,
                        resourceCode: Constants.ResourceCode.SYSTEM_SETTING,
                    },
                ],
            },

        ];

        setRoutes(mainRoutes);
    }, [key]);

    return routes;
};
