import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModuleCodename } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "../../constants";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { ITitleRoute } from "commons/interfaces";
import { setListPathName } from "store/slice/titleRoute.slice";

interface IModel {
    mode?: Mode;
    title?: string;
    route?: ITitleRoute[];
}

const CodenameScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const itemPathName = listPathName.find((el: any) => el.pathName === Screens.CODENAME_LIST);

    const idDetail: string = searchParams.get("id");
    const modeList = (Screens.CODENAME_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;


    const [model, setModel] = useState<IModel>({ mode: pramsMode || Mode.View });

    const [requestData] = useState<any>({
        code: searchParams.get("code"),
        group: searchParams.get("group"),
        searchText: searchParams.get("searchText"),
        totalCount: Number(itemPathName?.totalCount || 0),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            handleChangeMode(Mode.Create);
        } else {
            handleChangeMode(pramsMode || Mode.View);
        }
    }, [idDetail, pramsMode]);

    const handleChangeMode = (value: Mode) => {
        let title: string = "";
        let route = [{ title: Strings.CODE_NAME.TITLE_MENU, route: Screens.CODENAME_LIST }];

        if (value === Mode.Create) {
            title = Strings.CODE_NAME.TITLE_CREATE_VIEW;
            route = [
                { title: Strings.CODE_NAME.TITLE_MENU, route: Screens.CODENAME_LIST },
                { title: Strings.Common.CREATE_NEW, route: "" }
            ];
        }
        if (value === Mode.Update) {
            title = Strings.CODE_NAME.TITLE_UPDATE_VIEW;
            route = [
                { title: Strings.CODE_NAME.TITLE_MENU, route: Screens.CODENAME_LIST },
                { title: Strings.Common.UPDATE, route: "" }
            ];
        }
        if (value === Mode.View) {
            title = Strings.CODE_NAME.TITLE_DETAIL_VIEW;
            route = [
                { title: Strings.CODE_NAME.TITLE_MENU, route: Screens.CODENAME_LIST },
                { title: Strings.Common.VIEW, route: "" }
            ];
        }

        setModel({
            ...model,
            mode: value,
            title: title,
            route: route,
        });

        if (!Helpers.isNullOrEmpty(idDetail)) {
            navigate(Screens.CODENAME_EDIT + `?id=${idDetail}&mode=${value}`, { replace: true });
        }
    }

    return (
        <DashboardLayout
            title={modeList ? Strings.CODE_NAME.TITLE_LIST_VIEW : model.title}
            route={modeList ? [{ title: Strings.CODE_NAME.TITLE_MENU, route: "" }] : model.route}
        >
            <>
                <ModuleCodename
                    keyModuleContainer={{
                        list: { pathName: Screens.CODENAME_LIST },
                        detail: { pathName: Screens.CODENAME_EDIT },
                    }}
                    requestGetPaged={requestData}
                    onGetPaged={({ query, totalCount }) => {
                        dispatch(setListPathName({ pathname: Screens.CODENAME_LIST, query, totalCount: totalCount }));
                        navigate(Screens.CODENAME_LIST + query, { replace: true });
                    }}
                    onNavigate={(data) => {
                        if (data.id) {
                            navigate(Screens.CODENAME_EDIT + `?id=${data.id}&mode=${data.mode}`);
                        } else {
                            navigate(Screens.CODENAME_EDIT);
                        }
                    }}
                    //
                    mode={model.mode}
                    idDetail={idDetail}
                    onGoBack={() => {
                        const itemPathName = listPathName.find(el => el.pathName === Screens.CODENAME_LIST);
                        navigate(Screens.CODENAME_LIST + (itemPathName ? itemPathName.query : ""));
                    }}
                    onChangeMode={(value) => { handleChangeMode(value); }}
                />
            </>
        </DashboardLayout>
    );
};

export default CodenameScreen;
