import colors from "assets/theme/base/colors";

const { gradients } = colors;

type Types = any;

const tabs: Types = {
  styleOverrides: {
    root: {
      minHeight: "35px",
    },
    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },
    indicator: {
      backgroundColor: gradients.dark.main,
    }
  },
};

export default tabs;
