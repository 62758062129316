import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITitleRoute } from "../../commons/interfaces";

export interface TitleRouteState {
    titleScreen: string;
    route: ITitleRoute[];
    listPathName?: { pathName: string, query: string, totalCount: number }[];
}

const initialState: TitleRouteState = {
    titleScreen: "",
    route: [],
    listPathName: [],
}

export const titleRouteSlice = createSlice({
    name: "titleRoute",
    initialState,
    reducers: {
        updateTitleRoute: (state, action: PayloadAction<TitleRouteState>) => {
            state.route = action.payload.route;
            state.titleScreen = action.payload.titleScreen;
        },
        setListPathName: (state, action: PayloadAction<{ pathname: string, query: string, totalCount?: number }>) => {
            let listTemp = [...state.listPathName || []];
            const index = listTemp.findIndex(el => el.pathName === action.payload.pathname);
            if (index === -1) {
                listTemp.push({
                    pathName: action.payload.pathname,
                    query: action.payload.query,
                    totalCount: action.payload.totalCount || 0,
                });
            } else {
                listTemp[index].query = action.payload.query;
                listTemp[index].totalCount = action.payload.totalCount || 0;
            }

            state.listPathName = listTemp;
        },
        resetTitleRoute: (state) => {
            state.route = [];
            state.titleScreen = "";
            state.listPathName = [];
        },
    }
});

// Action creators are generated for each case reducer function
export const { updateTitleRoute, setListPathName, resetTitleRoute } = titleRouteSlice.actions

export default titleRouteSlice.reducer;