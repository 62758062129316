import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box, FormField, Typography, Autocomplete, SelectMultiLanguage } from "@maysoft/common-component-react";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "../../constants";
import Screens from "constants/screens";

import FaqService from "services/message/faq.service";
import { setDataAlert } from "store/slice/message.slice";
import TopicService from "services/message/topic.service";

import { IInput, ITitleRoute } from "commons/interfaces";
import { showLoading } from "store/slice/loadingAPI.slice";

interface IDataFaq {
    id?: string;
    topicId?: IInput;
    question?: IInput;
    answer?: IInput;
    organizationId?: string;

    status?: number;
    updateTime?: string;
}

interface IDataMultiLang {
    question?: any;
    answer?: any;
}

interface IModel {
    mode?: Mode;
    title?: string;
    route?: ITitleRoute[];
}

const topicService = new TopicService();
const faqService = new FaqService();

const FaqEditScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const currentLanguage = Helpers.getItemInLocalStorage(Constants.StorageKeys.LANGUAGE, Constants.DefaultLanguage);

    const state = location?.state as any;
    const locationMode = state?.mode;
    const [isOnchange, setIsOnchange] = useState<boolean>(false);
    const [model, setModel] = useState<IModel>({ mode: locationMode });
    const [dataFaq, setDataFaq] = useState<IDataFaq>({} as IDataFaq);
    const [dataFaqMultiLang, setFaqMultiLang] = useState<IDataMultiLang>({} as IDataMultiLang);
    const [detail, setDetail] = useState<any>();

    const [topics, setTopics] = useState([]);
    const [language, setLanguage] = useState(currentLanguage);

    useEffect(() => {
        onStarted();
    }, [userProfile?.organizationId]);

    useEffect(() => {
        if (detail) {
            const newData = {
                topicId: { value: detail.topicId },
                answer: { value: detail.answer.value[language] },
                question: { value: detail.question.value[language] },
            };
            setDataFaq({ ...newData });
        }
    }, [language]);

    const onStarted = async () => {
        try {
            dispatch(showLoading(true));

            const idDetail: string = searchParams.get("id");

            let title: string = Strings.CODE_NAME.TITLE_CREATE_VIEW;
            let route = [
                { title: Strings.CODE_NAME.TITLE_MENU, route: Screens.CODENAME_LIST },
                { title: Strings.Common.ADD_NEW, route: "" },
            ];

            await getTopics();
            if (!Helpers.isNullOrEmpty(idDetail)) {
                const resultDetail: any = await faqService.getDetail(idDetail);
                const newData = {
                    id: idDetail,
                    topicId: { value: resultDetail.topicId },
                    answer: { value: resultDetail.answer.value[language] },
                    question: { value: resultDetail.question.value[language] },
                };

                setDetail(resultDetail);
                setDataFaq({ ...newData });
                setFaqMultiLang({
                    answer: resultDetail.answer,
                    question: resultDetail.question,
                });
                title = model.mode === Mode.View
                    ? Strings.FAQ.TITLE_DETAIL_VIEW : Strings.FAQ.TITLE_UPDATE_VIEW;
                route = [
                    { title: Strings.FAQ.TITLE_MENU, route: Screens.FAQ_LIST },
                    {
                        title: model.mode === Mode.View
                            ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                        route: ""
                    },
                ];

                setModel({ ...model, title, route });
            }

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getTopics = async () => {
        try {
            const newData = {
                serviceCode: Constants.SERVICE_CODE_DEFAULT,
                tenantCode: Constants.TENANT_CODE_DEFAULT,
            };

            const result = await topicService.getPaged(Helpers.handleFormatParams(newData));
            const _topics = result.items?.map((item: any) => {
                return {
                    id: item.id,
                    code: item.id,
                    name: item.topicName
                }
            });

            setTopics(_topics);
        } catch (error) {
            console.log("error", error)
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const checkedValidated = () => {
        let isCheck: boolean = true;
        let dataTemp: any = { ...dataFaq };

        if (Helpers.isNullOrEmpty(dataTemp["question"]?.value)) {
            isCheck = false;
            dataTemp["question"] = { error: Strings.Validation.REQUIRED };
        }
        if (Helpers.isNullOrEmpty(dataTemp["answer"]?.value)) {
            isCheck = false;
            dataTemp["answer"] = { error: Strings.Validation.REQUIRED };
        }
        if (Helpers.isNullOrEmpty(dataTemp["topicId"]?.value)) {
            isCheck = false;
            dataTemp["topicId"] = { error: Strings.Validation.REQUIRED };
        }

        if (!isCheck) {
            setDataFaq(dataTemp);
        }

        return isCheck;
    };

    const handleGoBack = () => { navigate(Screens.FAQ_LIST) };

    const handleOnSubmit = async () => {
        if (!checkedValidated()) {
            return;
        } else {
            try {
                dispatch(showLoading(true));
                let dataSubmit: any = {
                    topicId: dataFaq.topicId?.value,
                    question: dataFaqMultiLang.question,
                    answer: dataFaqMultiLang.answer
                };

                let result: any;
                if (Helpers.isNullOrEmpty(dataFaq?.id)) {
                    result = await faqService.create(dataSubmit);
                } else {
                    dataSubmit = {
                        ...dataSubmit,
                        id: dataFaq?.id,
                    };
                    result = await faqService.update(dataSubmit);
                }

                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    dispatch(setDataAlert({
                        message: Helpers.isNullOrEmpty(dataFaq?.id)
                            ? Strings.Message.CREATE_SUCCESS
                            : Strings.Message.UPDATE_SUCCESS,
                        type: "success"
                    }));
                }

                if (Helpers.isNullOrEmpty(dataFaq?.id)) {
                    handleGoBack();
                } else {
                    setModel({
                        ...model, mode: Mode.View,
                        title: Strings.FAQ.TITLE_DETAIL_VIEW,
                        route: [
                            { title: Strings.FAQ.TITLE_MENU, route: Screens.FAQ_LIST },
                            { title: Strings.Common.DETAIL, route: "" },
                        ],
                    });
                }

                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        }
    };

    return (
        <DashboardLayout
            title={model.title}
            route={model.route}
            onBackPress={() => {
                if (isOnchange) {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                } else {
                    handleGoBack();
                }
            }}
            nameActionPress={model.mode === Mode.View ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={model.mode === Mode.View
                ? () => {
                    setModel({
                        ...model, mode: Mode.Update,
                        title: Strings.FAQ.TITLE_UPDATE_VIEW,
                        route: [
                            { title: Strings.FAQ.TITLE_MENU, route: Screens.FAQ_LIST },
                            { title: Strings.Common.UPDATE, route: "" },
                        ],
                    })
                }
                : handleOnSubmit
            }
        >
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <Box p={2}>
                                <Box alignItems="center" sx={{ display: "flex", flexWrap: "wrap" }}>
                                    <Typography variant="h5">{Strings.FAQ.TITLE_DETAIL_VIEW}</Typography>
                                </Box>
                                <Box component="form">
                                    <Grid container spacing={3} p={2}>
                                        <Grid item xs={12} md={12}>
                                            <SelectMultiLanguage
                                                defaultLanguage={language}
                                                onChangeLanguage={(value) => setLanguage(value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                required
                                                mode={model.mode}
                                                data={topics || []}
                                                label={Strings.TOPIC.NAME}
                                                defaultValue={dataFaq?.topicId?.value}
                                                placeholder={Strings.TOPIC.SELECT_TOPIC}
                                                errorMessage={dataFaq?.topicId?.error}
                                                disabled={!Helpers.isNullOrEmpty(dataFaq?.id) && model.mode === Mode.View}
                                                onChange={(value) => {
                                                    setDataFaq({ ...dataFaq, topicId: { value } });
                                                }}
                                                isAllowCreateNew
                                                createNewCallback={() => {
                                                    // setShowAttr(true);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormField
                                                required
                                                maxLength={255}
                                                mode={model.mode}
                                                label={Strings.FAQ.QUESTION}
                                                errorMessage={dataFaq?.question?.error}
                                                placeholder={Strings.FAQ.INPUT_QUESTION}
                                                defaultValue={dataFaqMultiLang?.question?.value[language]}
                                                disabled={!Helpers.isNullOrEmpty(dataFaq?.id) && model.mode === Mode.View}
                                                onBlur={(value) => {
                                                    setIsOnchange(true);
                                                    setDataFaq({ ...dataFaq, question: { value } });

                                                    const newValue = { ...dataFaqMultiLang?.question?.value }
                                                    newValue[language] = value;

                                                    setFaqMultiLang({ ...dataFaqMultiLang, question: { value: newValue } });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormField
                                                required
                                                maxLength={255}
                                                mode={model.mode}
                                                label={Strings.FAQ.ANSWER}
                                                errorMessage={dataFaq?.answer?.error}
                                                placeholder={Strings.FAQ.INPUT_ANSWER}
                                                defaultValue={dataFaqMultiLang?.answer?.value[language] || ""}
                                                disabled={!Helpers.isNullOrEmpty(dataFaq?.id) && model.mode === Mode.View}
                                                onBlur={(value) => {
                                                    setIsOnchange(true);
                                                    setDataFaq({ ...dataFaq, answer: { value } });

                                                    const newValue = { ...dataFaqMultiLang?.answer?.value }
                                                    newValue[language] = value;

                                                    setFaqMultiLang({ ...dataFaqMultiLang, answer: { value: newValue } });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </DashboardLayout>
    );
};

export default FaqEditScreen;
