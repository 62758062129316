import Helpers from "commons/helpers";
import Constants from "../../constants";
import MessageService from "./message.service";

export interface IDataUpdateMulti {
    organizationId: string,
    systemSettings: [
        {
            settingCode: string,
            settingValue: string,
        }
    ],
}

export interface IDataUpdate {
    id: string,
    settingCode: string,
    settingValue: string,
}

export interface IDataCreate {
    settingCode: string,
    settingValue: string,
    serviceCode: string,
    tenantCode: string,
    organizationId: string,
}

class SystemSettingService extends MessageService {

    public getSystemSetting = async (settingCodes: string[]) => {
        const data = Helpers.handleFormatParams({ "SettingCodes": settingCodes });
        const result = await this.api.get({
            path: `${Constants.ApiPath.SYSTEM_SETTING.GET_SYSTEM_SETTING}?${data}`,
        });
        return result.data.result;
    }

    public getBySettingCode = async (settingCode: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.SYSTEM_SETTING.GET_BY_SETTING_CODE}/${settingCode}`,
        });
        return result.data.result;
    }

    public getBySettingCodes = async (settingCodes: string[], organizationId?: string) => {
        const params = Helpers.handleFormatParams({
            organizationId: organizationId,
            settingCodes: settingCodes
        });

        const result = await this.api.get({
            path: `${Constants.ApiPath.SYSTEM_SETTING.GET_BY_SETTING_CODES}?${params}`,
        });

        return result.data.result;
    }

    public create = async (data: IDataCreate) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SYSTEM_SETTING.CREATE,
            data: data
        });
        return result.data;
    }

    public update = async (data: IDataUpdate) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SYSTEM_SETTING.UPDATE,
            data: data
        });
        return result.data;
    }

    public updateMulti = async (data: IDataUpdateMulti) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SYSTEM_SETTING.UPDATE_MULTI,
            data: data
        });
        return result.data;
    }


}

export default SystemSettingService