import Constants from "../../constants";
import IdentityService from "./indentity.service";

interface IRequestGetAllService {
    searchText?: string;
    tenantCode?: string;
}

class ServiceService extends IdentityService {
    public getAll = async (data?: IRequestGetAllService): Promise<any[]> => {
        const result = await this.api.get({
            path: Constants.ApiPath.SERVICE.GET_ALL,
            query: data,
        });
        return result.data.result;
    };
}

export default ServiceService;
