/* eslint-disable import/no-anonymous-default-export */
// =============================================================================
// Lang for Vietnamese
// ==================================

export default {
    App: {
        TITLE: "Circle Admin",
        MANAGEMENT_ORGANIZATION: "Management Organization",
    },

    Languages: {
        VIETNAM: "Việt Nam",
        VI: "Tiếng Việt",
        EN: "Tiếng Anh",
    },

    Common: {
        WEB: "Web",
        NEXT: "Tiếp tục",
        PREVIEW: "Xem trước",
        APP_MOBILE: "App Mobile",
        SELECT: "Chọn",
        COMMON: "Dùng chung",
        CONTINUE: "Tiếp tục",
        PAUSE: "Tạm dừng",
        CANCEL: "Hủy",
        CANCELED: "Đã hủy",
        CANCEL_SELECT: "Bỏ chọn",
        DISPLAY: "Hiển thị",
        TOTAL: "Tổng",
        NOTIFICATION: "Thông báo",
        OK: "OK",
        SAVE: "Lưu",
        SAVE_TEMP: "Lưu tạm",
        COMPLETE: "Hoàn thành",
        WAIT_TEST: "Chờ kiểm tra",
        PROCESSING: "Đang xử lý",
        UPDATE: "Cập nhật",
        IN_ACTIVE: "Hủy kích hoạt",
        ACTIVE: "Kích hoạt",
        EMPTY_DATA: "Chưa có dữ liệu",
        NO_DATA: "Không có dữ liệu",
        ADD_NEW: "Thêm mới",
        ADD_IMAGE: "Thêm hình ảnh",
        GO_BACK: "Trở về",
        EXIT: "Thoát",
        CREATE_NEW: "Tạo mới",
        VIEW_ALL: "Xem tất cả",
        EDIT: "Chỉnh sửa",
        LOCK: "Khóa",
        LOCKED: "Đã bị khóa",
        DRAFT: "Nháp",
        UNBLOCK: "Mở khóa",
        DELETE: "Xóa",
        REPLACE: "Thay thế",
        UPLOAD: "Tải lên",
        UPLOAD_IMAGE: "Tải ảnh lên",
        SEARCH: "Tìm kiếm",
        SEE: "Xem",
        ACTION: "Thao tác",
        MILLION: "Tr",
        BILLION: "Tỷ",
        UNCONFIRM: "Hủy bỏ",
        FILTER: "LỌC",
        FILTER_LIST: "Lọc danh sách",
        RESET: "Hủy lọc",
        TYPE: "Loại",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        CODE: "Mã",
        INPUT: "Nhập",
        INPUT_NOTE: "Nhập ghi chú",
        EMAIL: "Email",
        TITLE: "Chức danh",
        DAY: "Ngày",
        // TABLE
        LABEL_DISPLAY_ROWS: "{0} - {1} trên {2}",
        DOWN: "Tải về",
        DETAIL: "Chi tiết",
        APPROVE: "Phê duyệt",
        REJECT: "Từ chối",
        REJECT_INVITATION: "Từ chối lời mời",
        GENDER: "Giới tính",
        TIME: "Thời gian",
        START_TIME: "Thời gian bắt đầu",
        END_TIME: "Thời gian kết thúc",
        DISABLE: "Vô hiệu hóa",
        STATUS: "Trạng thái",
        COPY: "Sao chép",
        IMPORT: "Nhập",
        EXPORT: "Xuất",
        CITY: "Thành phố",
        PROVINCE: "Tỉnh thành",
        SELECT_PROVINCE: "Chọn tỉnh thành",
        DISTRICT: "Quận/huyện",
        SELECT_DISTRICT: "Chọn quận/huyện",
        WARDS: "Phường/xã",
        SELECT_WARDS: "Chọn phường/xã",
        SEARCH_TEXT: "Tìm kiếm",
        ENTER_SEARCH_TEXT: "Nhập từ khóa vào",
        ORGANIZATION: "Tổ chức",
        ACTION_WITH_EXCEL_FILE: "Thao tác excel",
        EXPORT_EXCEL: "Xuất excel",
        IMPORT_EXCEL: "Nhập excel",
        SELECT_FILE_FROM_DEVIDE: "Chọn tệp từ thiết bị",
        INVALID_DATA: "Dữ liệu không hợp lệ",
        FIELD_REQUIRED: "Trường bắt buộc",
        ACCEPT: "Đồng ý",
        ATTENTION: "Chú ý",
        MALE: "Nam",
        FEMALE: "Nữ",
        ALL: "Tất cả",
        CHANGE_STATUS: "Đổi trạng thái",
        CONFIRM: "Xác nhận",
        DATE_FORMAT: "vi",
        CLOSE: "Đóng",
        PRINT: "In",
        EXPORT_PDF: "Xuất PDF",
        ADDRESS_LINE: "Địa chỉ",
        ADDRESS_LINE_PHD: "Nhập địa chỉ",
        NAME: "Họ và tên",
        NOTE: "Ghi chú",
        BIRTHDATE: "Sinh nhật",
        APPLY: "Áp dụng",
        NOT_APPLY: "Ngừng áp dụng",
        VIEW: "Xem chi tiết",
        HERE: "Tại đây",
        UPLOAD_FILE: "Tải tệp lên",
        LOAD_MORE: "Xem thêm",
        USE_ALL_SYSTEM: "Sử dụng toàn hệ thống",
        USE: "Sử dụng",
        FAX_NUMBER: "Số fax",
        TAX_NUMBER: "Mã số thuế",
        YES: "Có",
        NO: "Không",
        SELECT_STATUS: "Chọn trạng thái",
        INVITE: "Lời mời",
        SELECTED_RECORD: "Đã chọn {0} bảng ghi",
        UNREAD: "Chưa đọc",
        MARK_READ_ALL: "Đánh dấu đọc tất cả",
        ADDRESS_INFO: "Thông tin địa chỉ",
        PENDING_INVITATION: "Chờ xác nhận lời mời",
        DELETED: "Đã xóa",
        COUNTRY_CODE: "Mã quốc gia",
        SELECT_COUNTRY_CODE: "Chọn mã quốc gia",
        SMS: "SMS",
    },

    AlertStatus: {
        ERROR: "Lỗi",
        WARNING: "Cảnh báo",
        INFO: "Thông tin",
        SUCCESS: "Thành công",
        QUESTION: "Câu hỏi",
    },

    Message: {
        ERROR: "Đã có phát sinh lỗi trong quá trình xử lý, vui lòng thử lại sau. Xin lỗi vì đã gây bất tiện.",
        SUCCESS: "Thành công",
        PLACEHOLDER: "Chọn...",
        CONFIRM_LOGOUT: "Bạn có muốn đăng xuất?",
        CONFIRM_CREATE: "Bạn có chắc muốn tạo?",
        CONFIRM_UPDATE: "Bạn có chắc muốn cập nhật?",
        CONFIRM_DELETE: "Bạn có thực sự muốn xóa?",
        CONFIRM_DELETE_ALL: "Bạn có thực sự muốn xóa tất cả?",
        CONFIRM_BLOCK: "Bạn có thực sự muốn khoá tài khoản?",
        CONFIRM_SUBMIT: "Bạn có thực sự muốn gửi?",
        CONFIRM_CANCEL: "Bạn có thực sự muốn hủy?",
        CONFIRM_CANCEL_ACTIVE: "Bạn có thực sự muốn hủy kích hoạt?",
        CONFIRM_EXPORT_FILE: "Bạn có thực sự muốn tải tệp?",
        CONFIRM_APPROVE: "Bạn có thực sự muốn phê duyệt?",
        CONFIRM_REJECT: "Bạn có thực sự muốn từ chối?",
        CREATE_SUCCESS: "Tạo thành công",
        UPDATE_SUCCESS: "Cập nhật thành công",
        PAYMENT_SUCCESS: "Thanh toán thành công",
        UPDATE_STATUS_SUCCESS: "Cập nhật trạng thái thành công",
        EXPORT_FILE_SUCCESS: "Tải tệp thành công",
        DELETE_SUCCESS: "Xóa thành công",
        DELETE_ALL_SUCCESS: "Xóa tất cả thành công",
        BLOCK_SUCCESS: "Khoá thành công",
        CANCEL_SUCCESS: "Hủy thành công",
        APPROVE_SUCCESS: "Phê duyệt thành công",
        REJECT_SUCCESS: "Từ chối thành công",
        LOGIN_SUCCESS: "Đăng nhập thành công",
        LOGOUT_SUCCESS: "Đăng xuất thành công",
        CREATE_ERROR: "Tạo không thành công",
        BLOCK_ERROR: "Khoá không thành công",
        UPDATE_ERROR: "Cập nhật không thành công",
        EXPORT_FILE_ERROR: "Tải tệp không thành công",
        CANCEL_ERROR: "Hủy không thành công",
        APPROVE_ERROR: "Phê duyệt không thành công",
        REJECT_ERROR: "Từ chối không thành công",
        DELETE_ERROR: "Xóa không thành công",
        DELETE_ALL_ERROR: "Xóa tất cả không thành công",
        LOGIN_ERROR: "Đăng nhật không thành công",
        LOGOUT_ERROR: "Đăng xuất không thành công",
        EPR_TOKEN: "Phiên đăng nhập đã hết hạn!",
        PASSWORD_ERROR: "Tài khoản hoặc mật khẩu không đúng.",
        CREATE_USER_SUCCESS: "Thêm mới nhân viên thành công",
        PLEASE_COMPLETE_ALL_INFORMATION: "Vui lòng điền đầy đủ thông tin yêu cầu",
        INVALID_EMAIL: "Email không hợp lệ",
        INVALID_PHONENUMBER: "Số điện thoại không hợp lệ",
        CONFIRM_CHANGE_STATUS: "Bạn có chắc chắn muốn thay đổi trạng thái?",
        CHANGE_PHONE_NUMBER_SUCCESS: "Thay đổi số điện thoại thành công",
        CHANGE_PASSWORD_SUCCESS: "Thay đổi mật khẩu thành công",
        CHANGE_PROFILE_SUCCESS: "Thay đổi thông tin cá nhân thành công",
        NO_FUNCTION: "Xin lỗi! Hiện chưa có chức năng này!",
        CANCEL_ACTION_MESSAGE: "Thông tin chỉnh sửa chưa được lưu. Bạn chắc chắn muốn rời khỏi màn hình hiện tại không?",
        TOKEN_EXPIRED: "Hết hạn cho phiên đăng nhập! Vui lòng đăng nhập lại",
        USER_PROFILE_NOT_FOUND: "Không tìm thấy thông tin người dùng, vui lòng đăng nhập lại!",
        CONFIRM_EXPORT: "Bạn có muốn xuất file excel?",
        NOT_FORMAT_IMG: "Không đúng định dạng hình ảnh cho phép! (.png |.gif | .jpeg)",
        LIMIT_NUMBER_OF_PHOTO: "Giới hạn thêm tối thiểu là {0} ảnh và tối đa chỉ {1} ảnh",
        LIMIT_SIZE_OF_IMG: "Kích thước file lớn hơn {0}MB",
        NOT_FOUND: "Không tìm thấy dữ liệu!",
        NOT_PERMISSION: "Rất tiếc, bạn không có quyền truy cập",
        NO_ROLE_UPDATE: "Bạn không có quyền cập nhật",
        NO_ROLE_DELETE: "Bạn không có quyền xóa",
        GROUP_ALREADY_EXIST: "Nhóm đã tồn tại",
        PLEASE_ADD_PERMISSION: "Vui lòng thêm nhóm quyền",
        PLEASE_ADD_GROUP_WORK: "Vui lòng thêm nhóm làm việc",
        DONT_HAVE_ORGANIZATION: "Để sử dụng chức năng này vui lòng tạo hoặc ",
        CORRESPONDING_ORGANIZATION: " tổ chức tương ứng.",
        CONFIRM_ACTIVE_STATUS: "Bạn chắc chắn muốn chuyển sang trạng thái kích hoạt?",
        CONFIRM_IN_ACTIVE_STATUS: "Bạn chắc chắn muốn chuyển sang trạng thái hủy kích hoạt?",
        IN_ACTIVE_STATUS_SUCCESS: "Cập nhật trạng thái sang hủy kích hoạt thành công",
        ACTIVE_STATUS_SUCCESS: "Cập nhật trạng thái sang kích hoạt thành công",
        CHANGE_EMAIL_SUCCESS: "Thay đổi email thành công",
        NO_ADDRESS: "Chưa có thông tin, vui lòng thêm địa chỉ",
    },

    Validation: {
        EMAIL_ADDRESS: "Sai định dạng email",
        PHONE_NUMBER: "Sai định dạng số điện thoại",
        DATE_TIME: "Sai định dạng ngày tháng năm",
        USERNAME: "Tên tài khoản viết liền không dấu",
        REQUIRED: "Trường dữ liệu là bắt buộc",
        REQUIRED_NAME: "Vui lòng nhập họ và tên",
        REQUIRED_EMAIL: "Vui lòng nhập email",
        REQUIRED_USER_NAME: "Vui lòng nhập tên đăng nhập",
        REQUIRED_PASSWORD: "Vui lòng nhập mật khẩu",
        REQUIRED_CONFIRM_PASSWORD: "Vui lòng nhập lại mật khẩu",
        REQUIRED_IMAGE: "Vui lòng chọn ảnh",
        REQUIRED_ORGANIZATION: "Vui lòng chọn cửa hàng",
        REQUIRED_ADDRESS: "Vui lòng thêm địa chỉ",
        NOT_SAME: "Mật khẩu không trùng khớp",
        REQUIRED_NUMBER: "Vui lòng chỉ nhập số",
        LIMITED_SIZE: "Đã vượt quá độ dài cho phép",
        REQUIRED_FORM: "Vui lòng kiểm tra lại thông tin nhập",
        REQUIRED_AVATAR: "Vui lòng chọn ảnh đại diện",
        INVALID_TIME: "Thời gian không hợp lệ",
        ID_CARD: "Sai định dạng CMND/CCCD",
        REQUIRED_SPECIAL_CHARACTERS: "Trường dữ liệu không chứa các ký tự đặc biệt",
    },

    Login: {
        LOGOUT: "Đăng xuất",
        USER_INFO: "Thông tin tài khoản",
    },

    PROFILE: {
        PROFILE: "Hồ sơ",
        TITLE_MENU: "Thông tin cá nhân",
        LIST_ORGANIZATION: "Danh sách tổ chức",
        NO_CHOOSE: "Không chọn",
    },

    DASHBOARD: {
        TITLE_MENU: "Dashboard",
        CREATE_ORGANIZATION: "Tạo mới tổ chức",
    },

    SETTING: {
        TITLE_MENU: "Cấu hình",
        SYSTEM_SETTING: "Cấu hình hệ thống",
        SYSTEM_SETTING_LIST: "Danh sách cấu hình",
        TITLE_UPDATE_VIEW: "Cập nhật cấu hình",
        TITLE_CREATE_VIEW: "Tạo mới cấu hình",
        TITLE_DETAIL_VIEW: "Thông tin cấu hình",

        CODE: "Mã",
        NAME: "Tên",
        VALUE: "Giá trị",
        DESCRIPTION: "Mô tả",
        ENTER_CODE: "Nhập mã",

        SETTING_CODE_000000: "Cấu hình firebase",
        SETTING_CODE_000001: "Cấu hình khác",
    } as any,

    MESSAGE_TEMPLATE: {
        TITLE_MENU: "Mẫu thông báo",
        TITLE_LIST_VIEW: "Danh sách mẫu thông báo",
        TITLE_UPDATE_VIEW: "Cập nhật mẫu thông báo",
        TITLE_DETAIL_VIEW: "Thông tin mẫu thông báo",
        TITLE_CREATE_VIEW: "Thêm mới mẫu thông báo",
    },

    MESSAGE_TEMPLATE_CASE: {
        TITLE_MENU: "Hộp mẫu thông báo",
        TITLE_LIST_VIEW: "Danh sách hộp mẫu thông báo",
        TITLE_UPDATE_VIEW: "Cập nhật hộp mẫu thông báo",
        TITLE_DETAIL_VIEW: "Thông tin hộp mẫu thông báo",
        TITLE_CREATE_VIEW: "Thêm mới hộp mẫu thông báo",
    },

    SETTING_FIREBASE: {
        BASIC_INFO: "Thông tin cấu hình firebase",

        ADMIN_SDK: "Admin SDK",
        VAPID_KEY: "Vapid Key",
        CLIENT_CONFIG: "Client Config",
        SERVICE_NAME: "Tên dịch vụ",
        SELECT_SERVICE: "Chọn dịch vụ",
        ENTER_ADMIN_SDK: "Nhập Admin SDK",
        ENTER_VAPID_KEY: "Nhập Vapid Key",
        ENTER_CLIENT_CONFIG: "Nhập Client Config",
    },

    SETTING_KEY: {
        BASIC_INFO: "Thông tin cấu hình",

        TYPE: "Loại",
        CODE: "Mã",
        VALUE: "Giá trị",
        ENTER_CODE: "Nhập mã",
        ENTER_VALUE: "Nhập giá trị",
    },

    CODE_NAME: {
        TITLE_MENU: "Danh mục dùng chung",
        TITLE_LIST_VIEW: "Danh sách danh mục",
        TITLE_CREATE_VIEW: "Thêm mới danh mục",
        TITLE_UPDATE_VIEW: "Cập nhật thông tin danh mục",
        TITLE_DETAIL_VIEW: "Chi tiết thông tin danh mục",
        BASIC_INFO: "Thông tin danh mục",
        CODE_NAME_DETAIL: "Chi tiết danh mục",
        PARENT_GROUP_CODE: "000000",
        CODE: "Mã danh mục",
        INPUT_CODE: "Nhập mã danh mục",
        NAME: "Tên danh mục",
        INPUT_NAME: "Nhập tên danh mục",
        SHORT_NAME: "Tên viết tắt",
        INPUT_SHORT_NAME: "Nhập tên viết tắt",
        SEARCH_TEXT: "Tìm kiếm theo tên",

        NAME_LIST: "Danh sách danh mục thuộc",
        PARENT_GROUP: "Danh mục cha",
    },

    TOPIC: {
        TITLE_MENU: "Quản lý chủ đề",
        TITLE_LIST_VIEW: "Danh sách chủ đề",
        TITLE_CREATE_VIEW: "Thêm mới chủ đề",
        TITLE_UPDATE_VIEW: "Cập nhật thông tin chủ đề",
        TITLE_DETAIL_VIEW: "Chi tiết thông tin chủ đề",
        NAME: "Chủ đề",
        SELECT_TOPIC: "Chọn chủ đề"
    },

    FAQ: {
        TITLE_MENU: "Quản lý câu hỏi",
        TITLE_LIST_VIEW: "Các câu hỏi thường gặp",
        TITLE_CREATE_VIEW: "Thêm mới câu hỏi",
        TITLE_UPDATE_VIEW: "Cập nhật thông tin câu hỏi",
        TITLE_DETAIL_VIEW: "Chi tiết thông tin câu hỏi",
        QUESTION: "Câu hỏi",
        ANSWER: "Trả lời",
        INPUT_QUESTION: "Nhập câu hỏi",
        INPUT_ANSWER: "Nhập câu trả lời",
        SEARCH_TEXT: "Tìm kiếm theo câu hỏi hoặc câu trả lời",
        INPUT_SEARCH_TEXT: "Nhập câu hỏi hoặc câu trả lời"
    },

    COMMUNITY: {
        TITLE_MENU: "Quản lý cộng đồng",
        TITLE_LIST_VIEW: "Danh sách cộng đồng",
        TITLE_CREATE_VIEW: "Thêm mới cộng đồng",
        TITLE_UPDATE_VIEW: "Cập nhật thông tin cộng đồng",
        TITLE_DETAIL_VIEW: "Chi tiết thông tin cộng đồng",
    },
};
